import {
    ACTION_APP_OPS_SIDEMENU_DRAWER_OPEN,
    ACTION_APP_OPS_SIDEMENU_PAYMENT_METHODS_ACTIVE,
    ACTION_APP_OPS_SIDEMENU_PROFILE_EDIT_ACTIVE,
    ACTION_APP_OPS_SIDEMENU_INVITE_CONTACTS_ACTIVE,
    ACTION_APP_OPS_SIDEMENU_MY_SUBSCRIPTIONS_ACTIVE,
    ACTION_APP_OPS_SIDEMENU_MY_POSTS_ACTIVE,
    ACTION_APP_OPS_SIDEMENU_POST_CREATE,
    ACTION_APP_OPS_SIDEMENU_DISABLE_ALL,
    ACTION_APP_OPS_DIALOG_SET_PASSWORD
} from './type'

export function setDrawerOpen(open) {
    return {
        type: ACTION_APP_OPS_SIDEMENU_DRAWER_OPEN,
        open: open
    }
}

export function disableAll() {
    return {
        type: ACTION_APP_OPS_SIDEMENU_DISABLE_ALL,
        payment_methods: false,
        my_subscriptions: false,
        profile_edit: false,
        my_posts: false,
        invite_contacts: false
    }
}

export function setPaymentMethods(active) {
    return {
        type: ACTION_APP_OPS_SIDEMENU_PAYMENT_METHODS_ACTIVE,
        payment_methods: active
    }
}

export function setMySubscriptions(active) {
    return {
        type: ACTION_APP_OPS_SIDEMENU_MY_SUBSCRIPTIONS_ACTIVE,
        my_subscriptions: active
    }
}

export function setMyPosts(active) {
    return {
        type: ACTION_APP_OPS_SIDEMENU_MY_POSTS_ACTIVE,
        my_posts: active
    }
}

export function triggerPostCreate() {
    return {
        type: ACTION_APP_OPS_SIDEMENU_POST_CREATE,
        post_create: true,
        my_posts: true
    }
}

export function setProfileEdit(active) {
    return {
        type: ACTION_APP_OPS_SIDEMENU_PROFILE_EDIT_ACTIVE,
        profile_edit: active
    }
}

export function setInviteContacts(active) {
    return {
        type: ACTION_APP_OPS_SIDEMENU_INVITE_CONTACTS_ACTIVE,
        invite_contacts: active
    }
}

export function setPasswordDialogOpen(open) {
    return {
        type: ACTION_APP_OPS_DIALOG_SET_PASSWORD,
        password_open: open
    }
}
