import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux'
import { isEmpty, useFirebase } from 'react-redux-firebase'

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';

import LoginScreen from './Login/LoginScreen';
import EmailVerification from './Login/EmailVerification';
import { setPasswordDialogOpen } from '../redux/action/appOps'

const useStyles = makeStyles(theme => ({
    loginContent: {
        maxWidth: '400px',
        margin: '0 auto'
    }
}));

function LoginProvider(props) {
    const classes = useStyles();

    const { dispatch, auth, children } = props;
    const [loggedIn, setLoggedIn] = React.useState(false);
    const [emailOpen, setEmailOpen] = React.useState(true);
    const [email, setEmail] = React.useState('');
    const [loginError, setLoginError] = React.useState('');

    let authenticationProviders = auth?.providerData?.map(data => {
        return data.providerId
    }) || [];

    const emailVerificationRequired = auth?.emailVerified === false &&
        !authenticationProviders.includes('facebook.com');

    const firebase = useFirebase();

    const _handleEmail = (e) => {
        setEmail(e.target.value)
    }

    const _handleEmailSubmit = () => {
        setEmailOpen(false);
      };
    
    if (loginError) {
        return (
            <Dialog aria-labelledby="error-dialog-title" open={true}>
                <DialogTitle id="error-dialog-title">Error with login link</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        There was an error with the following message: '{loginError}'. Please refresh the page to try again or try the link again.
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        )
    } else if (!loggedIn && firebase.auth().isSignInWithEmailLink(window.location.href)) {
        if (emailOpen) {
            return (
                <Dialog aria-labelledby="simple-dialog-title" open={emailOpen}>
                    <DialogTitle id="email-dialog-title">Enter your email address</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Please enter the same email address to which this login link was sent.  This is for security.
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Email Address"
                            type="email"
                            fullWidth
                            onChange={_handleEmail}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={_handleEmailSubmit} color="primary">
                            Continue
                        </Button>
                    </DialogActions>
                </Dialog>
            )
          }
         firebase.auth().signInWithEmailLink(email, window.location.href)
          .then(function(result) {
            dispatch(setPasswordDialogOpen(true));
            setLoggedIn(true)
          })
          .catch(function(error) {
            setLoginError(error.message);
          });
      
        
    } else if (isEmpty(auth)) {
        return (
            <div className={classes.loginContent}>
                <LoginScreen />
            </div>
        )
    } else if (emailVerificationRequired) {
        return (
            <div className={classes.loginContent}>
                <EmailVerification />
            </div>
        )
    } else {
        return (
            <React.Fragment>
                {children}
            </React.Fragment>
        )
    }
    return [];
}

const enhance = compose(
    connect(({ firebase: { auth } }) => ({ auth }))
)

export default enhance(LoginProvider)
