import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Translate from '../translate/translate'

const useStyles = makeStyles(theme => ({
    button: {
        'background-color': theme.mylawyer.colors.secondary,
        'border-radius': '4px',
        'text-transform': 'none',
        '&:hover': {
            'background-color': theme.mylawyer.colors.secondaryHover
        }
    },
}));

export default function CustomizedButtons(props) {
    const classes = useStyles();

    const { value, onClick, disabled } = props;

    return (
        <Button
            variant="contained"
            color="primary"
            onClick={onClick}
            className={classes.button}
            disabled={!!disabled}
        >
            <Translate value={value} />
        </Button>
    );
}
