import React, { useState } from 'react'

import strings from '../../translate/strings'
import { translate } from '../../translate/translate'
import ListHeader from '../ListHeader'
import GoogleLocation from '../Autocomplete/GoogleLocation'

import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import {
    IconButton,
    TextField,
    InputAdornment,
    Select,
    MenuItem,
    InputLabel,
    FormControl
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    formControl: {
        backgroundColor: '#ffffff',
        width: '100%',
        marginBottom: '10px'
    },
    formLabel: {
        marginLeft: '10px'
    },
    buttonColor: {
        color: theme.mylawyer.colors.textWithPrimary
    },
    searchForm: {
        marginTop: 5
    }
}));

export default function SearchForm(props) {
    const classes = useStyles();

    const [searchFormOpen, setSearchFormOpen] = useState();
    const [searchTerm, setSearchTerm] = useState();
    const [specialismSelected, setSpecialismselected] = useState();

    const {
        specialismOptions,
        onChangeSearchTerm,
        onChangeSpecialism,
        onChangeLocation
    } = props;

    function handleChangeSearchTerm(event) {
        const term = event.target.value;
        setSearchTerm(term);
        onChangeSearchTerm(term);
    }

    function handleChangeSpecialism(event) {
        const specialism = event.target.value;
        setSpecialismselected(specialism);
        onChangeSpecialism(specialism);
    }

    return (
        <React.Fragment>
            <ListHeader key="UserListHeader"
                label={strings.global_network_title}
                button={(
                    <IconButton
                        className={classes.buttonColor} 
                        aria-label="search"
                        component="span"
                        onClick={() => setSearchFormOpen(!searchFormOpen)}
                    >
                        <SearchIcon />
                    </IconButton>
                )}
            />
            {searchFormOpen &&
                <form className={classes.searchForm} noValidate autoComplete="off">
                    <FormControl className={classes.formControl} >
                        <TextField
                            id="search-network-query"
                            label={translate(strings.global_network_search_term_lable)}
                            variant="outlined"
                            value={searchTerm}
                            onChange={handleChangeSearchTerm}
                            InputProps={{
                                startAdornment: (<InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>)
                            }}
                            autoFocus
                        />
                    </FormControl>
                    <FormControl className={classes.formControl} >
                        <InputLabel
                            id="search-network-specialism-label"
                            className={classes.formLabel} >Specialism</InputLabel>
                        <Select
                            id="search-network-specialism"
                            label={translate(strings.global_network_specialism_lable)}
                            labelId="search-network-specialism-label"
                            variant="outlined"
                            value={specialismSelected}
                            onChange={handleChangeSpecialism}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {specialismOptions}
                        </Select>
                    </FormControl>
                    <GoogleLocation onChange={onChangeLocation} />
                </form>
            }
        </React.Fragment>
    )
}
