import { combineReducers } from 'redux'
import { firebaseReducer } from 'react-redux-firebase'
import { firestoreReducer } from 'redux-firestore'
import { translateReducer } from '../../translate/redux'
import bulletinBoardReducer from './bulletinBoard'
import channelOpsReducer from './channelOps'
import encryptionReducer from './encryption'
import myNetworkReducer from './myNetwork'
import userProfileReducer from './userProfile'
import globalNetworkReducer from './globalNetwork'
import billingReducer from './billing'
import appOpsReducer from './appOps'
import caseOpsReducer from './caseOps'
import firebaseAuthReducer from './firebaseAuth'

// Add firebase to reducers
const rootReducer = combineReducers({
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    bulletinBoard: bulletinBoardReducer,
    translate: translateReducer,
    channelOps: channelOpsReducer,
    encryption: encryptionReducer,
    myNetwork: myNetworkReducer,
    globalNetwork: globalNetworkReducer,
    userProfile: userProfileReducer,
    billing: billingReducer,
    appOps: appOpsReducer,
    caseOps: caseOpsReducer,
    firebaseAuth: firebaseAuthReducer,
});

export { rootReducer }
