import firebase from 'firebase/app'
import 'firebase/auth'
const axios = require('axios').default;

let userMyNetwork;
let vFilterMyNetwork;

export function fetchMyNetwork(user_id,
    onSuccess = (user_id, hits) => { },
    onError = (user_id, error) => { }) {

    if (!user_id) {
        console.warn("no user")
        onError(user_id)
        return;
    }
    const currentUser = firebase.auth().currentUser;
    if (!currentUser || currentUser.uid !== user_id) {
        console.warn("no authenticated user")
        onError(user_id)
        return;
    }

    currentUser.getIdToken().then((idToken) => {
        if (!idToken) {
            console.warn("no token")
            onError(user_id)
            return;
        }


        //cancel any existing request
        userMyNetwork && userMyNetwork.cancel('canceled by user')
        userMyNetwork = axios.CancelToken.source();

        const config = {
            headers: { Authorization: `Bearer ${idToken}` },
            cancelToken: userMyNetwork.token
        };

        axios
            .get("https://mylawyer-search.appspot.com/search/contact", config)
            .then((response) => {
                onSuccess(user_id, response.data?.hits)
            }).catch((error) => {
                onError(user_id, error)
            });
    });
}

export function filterMyNetwork(user_id, phrase,
    onSuccess = (user_id, hits) => { },
    onError = (user_id, error) => { }) {

    if (!user_id) {
        console.warn("no user")
        onError(user_id)
        return;
    }
    const currentUser = firebase.auth().currentUser;
    if (!currentUser || currentUser.uid !== user_id) {
        console.warn("no authenticated user")
        onError(user_id)
        return;
    }

    currentUser.getIdToken().then((idToken) => {
        if (!idToken) {
            console.warn("no token")
            onError(user_id)
            return;
        }


        //cancel any existing request
        vFilterMyNetwork && vFilterMyNetwork.cancel('canceled by user')
        vFilterMyNetwork = axios.CancelToken.source();

        const config = {
            headers: { Authorization: `Bearer ${idToken}` },
            cancelToken: vFilterMyNetwork.token
        };

        axios
            .get("https://mylawyer-search.appspot.com/search/contact?q=" + encodeURIComponent(phrase), config)
            .then((response) => {
                onSuccess(user_id, response.data?.hits)
            }).catch((error) => {
                onError(user_id, error)
            });
    });
}

let initialGlobalNetwork;

export function initialFetchGlobalNetwork(user_id,
    onSuccess = (user_id, hits) => { },
    onError = (user_id, error) => { }) {

    const currentUser = firebase.auth().currentUser;
    if (!currentUser || currentUser.uid !== user_id) {
        console.warn("no authenticated user")
        onError(user_id)
        return;
    }

    currentUser.getIdToken().then((idToken) => {
        if (!idToken) {
            console.warn("no token")
            onError(user_id)
            return;
        }


        //cancel any existing request
        initialGlobalNetwork && initialGlobalNetwork.cancel('canceled by user')
        initialGlobalNetwork = axios.CancelToken.source();

        const config = {
            headers: { Authorization: `Bearer ${idToken}` },
            cancelToken: initialGlobalNetwork.token
        };

        axios
            .get("https://mylawyer-search.appspot.com/search/global?size=20", config)
            .then((response) => {
                onSuccess(user_id, response.data?.hits)
            }).catch((error) => {
                onError(user_id, error)
            });
    });
}

export function searchGlobalNetwork(user_id, phrase, specialisms, location, pageStart, pageSize,
    onSuccess = (user_id, hits) => { },
    onError = (user_id, error) => { }) {

    const currentUser = firebase.auth().currentUser;
    if (!currentUser || currentUser.uid !== user_id) {
        console.warn("no authenticated user")
        onError(user_id)
        return;
    }

    currentUser.getIdToken().then((idToken) => {
        if (!idToken) {
            console.warn("no token")
            onError(user_id)
            return;
        }

        let query = [];
        if (phrase) {
            query.push({ key: 'q', value: encodeURIComponent(phrase) })
        }
        if (specialisms) {
            query.push({ key: 'specialism', value: encodeURIComponent(specialisms) })
        }
        if (location) {
            query.push({ key: 'location', value: encodeURIComponent(location) })
        }
        if (typeof pageStart != 'undefined' && pageStart != null) {
            query.push({ key: 'from', value: parseInt(pageStart) })
        }
        if (pageSize) {
            query.push({ key: 'size', value: parseInt(pageSize) })
        }
        let queryString = query.map(e => (e.key + '=' + e.value)).join('&');
        //cancel any existing request
        initialGlobalNetwork && initialGlobalNetwork.cancel('canceled by user')
        initialGlobalNetwork = axios.CancelToken.source();

        const config = {
            headers: { Authorization: `Bearer ${idToken}` },
            cancelToken: initialGlobalNetwork.token
        };

        axios
            .get("https://mylawyer-search.appspot.com/search/global?" + queryString, config)
            .then((response) => {
                onSuccess(user_id, response.data?.hits)
            }).catch((error) => {
                onError(user_id, error)
            });
    });
}
