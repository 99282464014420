import React from "react";
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Translate from '../../translate/translate'

const useStyles = makeStyles(theme => ({
    root: {
        'border-bottom': `solid ${theme.mylawyer.colors.primaryVariant}`,
        margin: '0',
        backgroundColor: `${theme.mylawyer.colors.primary}`,
        color: theme.mylawyer.colors.secondary,
    },
    indicator: {
        display: 'none'
    },
    iconColor: {
        color: theme.mylawyer.colors.textWithPrimary,
        opacity: 1
    },
    selectedIconColor: {
        color: theme.mylawyer.colors.secondaryVariant,
        opacity: 1
    },
}));

function MenuTabs(props) {
    const classes = useStyles();

    const tabLibrary = props.menu.map(currentMenuItem => {
        const label = <Translate value={currentMenuItem.label} />
        return <Tab
            classes={{labelIcon: classes.iconColor, selected: classes.selectedIconColor }}
            component={Link}
            to={currentMenuItem.path}
            key={currentMenuItem.path}
            label={label}
            icon={currentMenuItem.icon}
            value={currentMenuItem.path} />
    });

    return (
        <Tabs
            classes={{ indicator: classes.indicator, root: classes.root }}
            indicator="inherit"
            centered
            value={props.location.pathname} >
            {tabLibrary}
        </Tabs>
    )
}

export default withRouter(MenuTabs)
