import React from "react";
import { compose } from 'redux'
import { connect } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles';
import { Grid, IconButton, Divider } from "@material-ui/core";
import ContactsIcon from '@material-ui/icons/Contacts';
import PublicIcon from '@material-ui/icons/Public';
import { setGlobalNetworkActive } from '../../redux/action/globalNetwork'
import GlobalNetworkList from "../Network/GlobalNetworkList";
import MyNetworkList from "../Network/MyNetworkList";
import UserProfile from "../Profile/UserProfile";
import { Translate, strings } from '../../translate'

import CompareArrowsIcon from '@material-ui/icons/CompareArrows';

const useStyles = makeStyles(theme => ({
    root: {
        'border-bottom': `solid ${theme.mylawyer.colors.primaryVariant}`,
        margin: '0 10px',
        'border-left': `1px solid ${theme.mylawyer.colors.primaryVariant}`,
        height: '100%',
        backgroundColor: theme.mylawyer.colors.background,
        color: theme.mylawyer.colors.text
    },
    indicator: {
        display: 'none'
    },
    networkSwitch: {
        display: 'flex',
        justifyContent: 'center',
        borderBottom: `3px solid ${theme.mylawyer.colors.primaryVariant}`,
        padding: '0px 12px 8px 12px !important',
        boxSizing: 'border-box',
        backgroundColor: theme.mylawyer.colors.primary,
        color: theme.mylawyer.colors.textWithPrimary
    },
    toggleArea: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        borderBottom: `1px solid ${theme.mylawyer.colors.primaryVariant}`,
        boxSizing: 'border-box'
    },
    icon: {
        color: theme.mylawyer.colors.textWithPrimary
    },
    iconLabel: {
        textTransform: 'uppercase',
        width: '100%',
        textAlign: 'center',
        fontSize: '0.8em'
    },
    activeIcon: {
        color: theme.mylawyer.colors.secondary
    },
    profileArea: {
        backgroundColor: theme.mylawyer.colors.primary,
        borderLeft: `1px solid ${theme.mylawyer.colors.primaryVariant}`,
        height: '100%',
        minHeight: '100vh',
        padding: 20,
        marginTop: 8
    }
}));

/**
 * Provides a network list with profile sliding out on select
 * @param {*} props
 */
function NetworkBladeManager(props) {
    const classes = useStyles();

    const { dispatch, selected_user_id, activate_side_bar, global_network_active, networkComponent = null } = props;

    const showGlobalNetwork = typeof global_network_active != 'undefined' && global_network_active === true;

    function _showGlobalNetwork() {
        dispatch(setGlobalNetworkActive(true));
    }
    function _showMyNetwork() {
        dispatch(setGlobalNetworkActive(false));
    }

    function _toggleGlobalNetwork() {
        dispatch(setGlobalNetworkActive(!global_network_active));
    }

    let networkBladeListComponent = "";

    //Allow override network component
    if (networkComponent) {
        if (selected_user_id && activate_side_bar) {
            networkBladeListComponent = (
                <React.Fragment>
                    <Grid item xs={12} md={4}>
                        {networkComponent}
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <UserProfile />
                    </Grid>
                </React.Fragment>
            );
        }
        else {
            networkBladeListComponent = (
                <React.Fragment>
                    <Grid item xs={12} md={12}>
                        {networkComponent}
                    </Grid>
                </React.Fragment>
            );
        }

    }
    else if (selected_user_id && activate_side_bar) {
        //If there is an active selected_user_id, show the profile blade
        networkBladeListComponent = (
            <Grid container>
                <Grid item xs={12} md={4}>
                    {!showGlobalNetwork && <MyNetworkList />}
                    {showGlobalNetwork && <GlobalNetworkList />}
                </Grid>
                <Grid item xs={12} md={8} className={classes.profileArea}>
                    <UserProfile />
                </Grid>
            </Grid>
        );
    } else {
        networkBladeListComponent = (
            <Grid item xs={12} md={12}>
                {!showGlobalNetwork && <MyNetworkList />}
                {showGlobalNetwork && <GlobalNetworkList />}
            </Grid>
        );
    }

    //@todo make this reponsive to the device
    const style = {
        maxWidth: ((selected_user_id && activate_side_bar) ? 700 : 250) + 'px'
    }

    const networkSwitchComponent = (
        <Grid item xs={12} className={classes.networkSwitch}>
            <div className={classes.iconWrapper}>
                <IconButton className={!showGlobalNetwork ? classes.activeIcon : classes.icon} aria-label="show hide details" component="span" onClick={() => _showMyNetwork()}>
                    <ContactsIcon />
                </IconButton>
                <div className={classes.iconLabel}><Translate value={strings.network_switch_my_network} /></div>
            </div>
            <IconButton className={classes.icon} aria-label="show hide details" component="span" onClick={() => _toggleGlobalNetwork()}>
                <CompareArrowsIcon />
            </IconButton>
            <div className={classes.iconWrapper}>
                <IconButton className={showGlobalNetwork ? classes.activeIcon : classes.icon} aria-label="show hide details" component="span" onClick={() => _showGlobalNetwork()}>
                    <PublicIcon />
                </IconButton>
                <div className={classes.iconLabel}><Translate value={strings.network_switch_global} /></div>
            </div>
        </Grid>
    )

    return (
        <div className={classes.root} style={style}>
            <Grid container>
                {networkComponent === null && networkSwitchComponent}
                <Divider />
                {networkBladeListComponent}
            </Grid>
        </div>
    )
}

const enhance = compose(
    connect(
        // Map redux state to component props
        ({
            myNetwork: { selected_user_ids },
            globalNetwork: { global_network_active },
            userProfile: { selected_user_id, activate_side_bar },
        }) => ({
            selected_user_ids,
            global_network_active,
            selected_user_id,
            activate_side_bar
        })
    )
)


export default enhance(NetworkBladeManager);


