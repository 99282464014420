import {
    ACTION_BILLING_INITIAL_FETCH_REQUEST,
    ACTION_BILLING_INITIAL_FETCH_RESULT,
    ACTION_BILLING_CUSTOMER_FETCH_REQUEST,
    ACTION_BILLING_CUSTOMER_FETCH_RESULT,
} from "../action/type";

function billingCustomerFetch(state = {}, action) {
    switch (action.type) {
        case ACTION_BILLING_CUSTOMER_FETCH_REQUEST: {
            return Object.assign({}, state, {
                fetching: true
            });
        }
        case ACTION_BILLING_CUSTOMER_FETCH_RESULT: {
            //Get Braintree billing plans and put into redux
            // const processedResult = action?.results?.map((customerItem) => {
            //     return {
            //         ...customerItem,
            //     }
            // });

            return Object.assign({}, state, {
                fetching: false,
                data: action.customer,
                updated: action.updated
            });
        }
        default:
            return state
    }
}

function billingPlansFetch(state = {}, action) {
    switch (action.type) {
        case ACTION_BILLING_INITIAL_FETCH_REQUEST: {
            return Object.assign({}, state, {
                fetching: true
            });
        }
        case ACTION_BILLING_INITIAL_FETCH_RESULT: {
            //Get Braintree billing plans and put into redux
            const processedResult = action?.results?.map((planItem) => {
                return {
                    ...planItem,
                }
            });

            return Object.assign({}, state, {
                fetching: false,
                results: processedResult,
                updated: action.updated
            });
        }
        default:
            return state
    }
}


export default function billingReducer(state = {}, action) {
    switch (action.type) {
        case ACTION_BILLING_INITIAL_FETCH_REQUEST:
        case ACTION_BILLING_INITIAL_FETCH_RESULT: {
            //Nested Object.assign to make sure only the new values are updated
            return Object.assign({}, state, {
                billing_plans: Object.assign({}, state?.billing_plans, {
                    results: billingPlansFetch(state?.['billing_plans'], action)
                })
            });
        }
        case ACTION_BILLING_CUSTOMER_FETCH_REQUEST:
        case ACTION_BILLING_CUSTOMER_FETCH_RESULT: {
            return Object.assign({}, state, {
                customer: Object.assign({}, state?.customer, {
                    data: billingCustomerFetch(state?.['customer'], action)
                })
            });
        }
        default:
            return state;
    }
}
