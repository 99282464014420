/**
 * This Theme will override and extend the default Material-UI theme
 * See https://material-ui.com/customization/default-theme/
 */

const color = {
    background: '#ffffff',
    primary: '#e7ecf3', //very-light-grey
    primaryVariant: '#d2d9e2', //light-grey
    secondary: '#d42617', //red
    secondaryVariant: '#a71e12', //dark-red
    text: '#3d4f62', //slate-blue
    textVariant: '#757575', //dark-grey
    textWithPrimary: '#3d4f62', //slate-blue
    textVariantBackground: '#d2d9e2',
    colorPrimaryDark: '#d2d9e2',
    textOnSecondary: '#ffffff',
    colorSecondaryDisabled: '#d2d9e2',
    colorPrimaryLight: '#f7f9fb',
    navbarBackground: '#3d4f62',
    textWithNavbar: '#ffffff',
    dialogBackground: '#ffffff',
    textOnDialog: '#3d4f62'
};

export default {
    palette: {
        text: {
            primary: color.text,
            secondary: color.textVariant
        },
        secondary: {
            light: color.secondaryVariant,
            main: color.secondary,
        }
    },
    typography: {
        fontFamily: '\'Lato\', sans-serif'
    },
    shape: {
        borderRadius: 0
    },
    mylawyer: {
        colors: color
    }
};
