import {
    ACTION_ENCRYPT_RECEIVE_MESSAGE,
} from '../action/type'

export default function encryptionReducer(state = {}, action) {
    switch (action.type) {
        case ACTION_ENCRYPT_RECEIVE_MESSAGE:
            if (typeof action.content !== 'string') {
                action.content = "";
            }
            return {
                ...state,
                message: {
                    ...state?.message,
                    [action.channel_id]: {
                        ...state.message?.[action.channel_id],
                        [action.message_id]: action.content
                    }
                }
            }
        default:
            return state;
    }
}
