import {
    ACTION_CASE_SELECT,
    ACTION_CASE_OPS_MORE,
} from '../action/type'

const initialState = {
    case_page_size: 10,
    channel_page_size: 10
};

export default function channelOpsReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_CASE_SELECT: {
            return Object.assign({}, state, {
                case_id: action.case_id,
            });
        }
        case ACTION_CASE_OPS_MORE: {
            return Object.assign({}, state, {
                case_page_size: state.case_page_size + 10
            });
        }
        default:
            return state;
    }
}
