import {
    ACTION_PROFILE_USER_SELECT,
    ACTION_NETWORK_INTENT_INVITATION
} from "../action/type";


export default function userProfileReducer(state = {}, action) {
    switch (action.type) {
        case ACTION_PROFILE_USER_SELECT: {
            return Object.assign({}, state, {
                selected_user_id: action.user_id,
                activate_side_bar: action.activate_side_bar
            });
        }
        case ACTION_NETWORK_INTENT_INVITATION: {
            return Object.assign({}, state, {
                intent_invitation: action.intent
            });
        }
        default:
            return state;
    }
}
