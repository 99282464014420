import {
    ACTION_MYNETWORK_USER_SELECT,
    ACTION_MYNETWORK_CONTACT_FETCH_REQUEST,
    ACTION_MYNETWORK_CONTACT_FETCH_RESULT,
    ACTION_MYNETWORK_USER_SEARCH_REQUEST,
    ACTION_MYNETWORK_USER_SEARCH_RESULT,
    ACTION_GLOBALNETWORK_SET_ACTIVE
} from './type'

export function userSelect(user_id) {
    let user_ids = [user_id];
    return {
        type: ACTION_MYNETWORK_USER_SELECT,
        user_ids
    }
}

export function userSelectMultiple(user_ids) {
    return {
        type: ACTION_MYNETWORK_USER_SELECT,
        user_ids
    }
}

export function contactFetchRequest(user_id) {
    return {
        type: ACTION_MYNETWORK_CONTACT_FETCH_REQUEST,
        user_id
    }
}

export function contactFetchResult(user_id, result) {
    return {
        type: ACTION_MYNETWORK_CONTACT_FETCH_RESULT,
        user_id,
        result,
        updated: Date.now()
    }
}

export function contactSearchRequest(phrase) {
    return {
        type: ACTION_MYNETWORK_USER_SEARCH_REQUEST,
        phrase
    }
}

export function contactSearchResult(phrase, result) {
    return {
        type: ACTION_MYNETWORK_USER_SEARCH_RESULT,
        phrase,
        result,
        updated: Date.now()
    }
}


