/**
 * This Theme will override and extend the default Material-UI theme
 * See https://material-ui.com/customization/default-theme/
 */

const color = {
    background: '#ffffff',
    primary: '#1c2541', //night-blue
    primaryVariant: '#3a506b', //dark-blue
    secondary: '#5bc0be', //teal
    secondaryVariant: '#368c8c', //dark-teal
    text: '#011627', //almost-black
    textVariant: '#1c2541', //very-dark
    textWithPrimary: '#ffffff', //white
    textVariantBackground: '#dbe3ec', //very-light-grey
    colorPrimaryDark: '#12182a',
    textOnSecondary: '#ffffff',
    colorSecondaryDisabled: '#d2e8e7', //light-grey
    colorPrimaryLight: '#517198',
    navbarBackground: '#1c2541',
    textWithNavbar: '#ffffff',
    dialogBackground: '#dbe3ec',
    textOnDialog: '#011627'
};

export default {
    palette: {
        text: {
            primary: color.text,
            secondary: color.textVariant
        },
        secondary: {
            light: color.secondaryVariant,
            main: color.secondary,
        }
    },
    typography: {
        fontFamily: '\'Lato\', sans-serif'
    },
    shape: {
        borderRadius: 0
    },
    overrides: {
        MuiDialogTitle: {
            root: {
                backgroundColor: color.dialogBackground,
                color: color.textOnDialog
            }
        },
        MuiDialogContent: {
            root: {
                backgroundColor: color.dialogBackground,
                color: color.textOnDialog,
                "& input, & label, & p": {
                    color: color.textOnDialog
                }
            }
        },
        MuiDialogActions: {
            root: {
                backgroundColor: color.dialogBackground,
                color: color.textOnDialog
            }
        }
    },
    mylawyer: {
        colors: color
    }
};
