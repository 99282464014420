import React, { useEffect, useState } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { useFirebaseConnect, useFirebase, isLoaded, isEmpty } from 'react-redux-firebase'

import { makeStyles } from '@material-ui/core/styles';
import { Button, Card, CardContent, Container, Divider, Grid, Link, LinearProgress,  Typography } from "@material-ui/core";

import Logo from '../../styles/Logo';


const useStyles = makeStyles(theme => ({
    logoArea: {
        margin: '50px 20px'
    },
    logo: {
        width: '100%'
    },
    message: {
        width: '100%',
        marginBottom: '20px',
        color: theme.mylawyer.colors.secondary
    },
    loginField: {
        width: '100%',
        marginBottom: '20px'
    },
    textField: {
        width: '100%',
        marginBottom: '20px'
    },
    loginBox: {

    },
    loginButton: {
        width: '100%',
        margin: '20px 0px'
    },
    icon: {
        color: theme.mylawyer.colors.textVariant,
        marginRight: theme.spacing(2),
    },
    centerText: {
        textAlign: 'center',
        width: '100%'
    },
    loginSupport: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'no-wrap',
        padding: 0,
        width: '100%'
    }
}));


function EmailVerification(props) {
    const classes = useStyles();
    const { auth } = props;
    const [loadingIndicator, setLoadingIndicator] = useState(true);
    const [message, setMessage] = useState('');
    const loaded = React.useRef(false);

    const firebase = useFirebase();

    useFirebaseConnect([])

    const _newUserStart = () => {

    }

    const _resendVerification = () => {
        firebase.auth().currentUser.sendEmailVerification().then(function() {
            setMessage('A verification email has been resent.  Please check your email and follow the link provided.')
        }).catch(error => {

        })
    }



    //We have two lists - contacts and contacts_search.  If there is a contacts_search which is not empty, return that

    if (!isEmpty(auth)) {
        const verification = (
            <Container className={classes.root}>
                <div className={classes.logoArea} ><Logo open/></div>
                    <div className={classes.message}>{message}</div>
                <Card className={classes.loginBox}>
                    <CardContent>
                        <LinearProgress/>
                        <Grid container>
                            <Typography className={classes.centerText}>Awaiting email verification</Typography>
                        </Grid>
                        <Grid container>
                            <Button className={classes.loginButton} variant="contained" color="primary" disableElevation onClick={_resendVerification}>Resend verification email</Button>
                        </Grid>
                        <Divider/>
                        <Grid container>
                            <ul className={classes.loginSupport}>
                                <li style={{'listStyleType': 'none', 'marginRight': '20px'}}>

                                </li>
                                <li>
                                    <Link href="#" onClick={ _newUserStart }>
                                        Sign up for an account
                                    </Link>
                                </li>
                            </ul>
                        </Grid>
                    </CardContent>
                </Card>
            </Container>
        )

        return (
            <div>
                {verification}
            </div>
        )
    }
    else {
        // Logged in - redirect to main page?
        return (<div></div>);
    }
}


const enhance = compose(
    connect(
        // Map redux state to component props
        ({
            firebase: { auth }
        }) => ({
            auth
        })
    )
)

export default enhance(EmailVerification);
