import React from "react";

import Translate from '../translate/translate'

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton'
import ArrowBack from '@material-ui/icons/ArrowBack'

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.mylawyer.colors.primary,
        color: theme.mylawyer.colors.textWithPrimary,
        'padding': '1em',
        'font-weight': 'bold',
        display: 'grid',
        gridTemplateColumns: '1fr min-content'
    },
    secondary: {
        color: theme.mylawyer.colors.textWithPrimary,
        fontStyle: 'italic',
        padding: '0 1em',
        display: 'inline-block'
    },
    button: {
        margin: '-12px 0px'
    },
    buttonLeft: {
        margin: '0px 0px 0px -12px',
        gridColumnEnd: '1'
    },
    buttonRight: {
        gridColumnEnd: '-1'
    },
    buttonLeft: {
        gridColumnEnd: '1'
    }
}));

function BackButton(props) {
    const classes = useStyles();
    return <IconButton
        className={classes.button}
        aria-label="back"
        component="span"
        onClick={props.onClick}>
        <ArrowBack />
    </IconButton>
};

function ListHeader(props) {
    const classes = useStyles();

    const { label, secondary, button, backButton, onBackButtonClick, buttonLeft } = props;

    return (
        <Paper elevation={0} className={classes.root}>
            {backButton &&
                <div className={classes.buttonLeft}>
                    <BackButton
                        className={classes.button}
                        onClick={onBackButtonClick} />
                </div>}
            {buttonLeft &&
                <div className={[classes.buttonLeft, classes.button].join(' ')}>
                    {buttonLeft}
                </div>}
            <div>
                <Translate value={label} />
                {secondary &&
                    <span className={classes.secondary}>
                        <Translate
                            value={secondary} />
                    </span>
                }
            </div>
            {button &&
                <div className={[classes.buttonRight, classes.button].join(' ')}>
                    {button}
                </div>}
        </Paper >
    )
}

export default ListHeader
