import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { all as strings } from './strings'

export function translate(key, locale = "en", count = 1) {
    const source = strings?.[locale]?.[key] || strings?.[key];

    if (typeof source === "string") {
        return source;
    }
    else if (typeof source === 'object' && source !== null) {
        if (count === 0 && typeof source?.zero === "string") {
            return source.zero;
        }
        else if (count === 1 && typeof source?.one === "string") {
            return source.one;
        }
        else if (typeof source?.other === "string") {
            return source.other;
        }
        else {
            return "";
        }
    }
    else {
        return key;
    }
}

function Translate(props) {

    const { value, locale, count = 1 } = props;

    const translatedValue = translate(value, locale, count);

    return (
        <React.Fragment>
            {translatedValue}
        </React.Fragment>
    )
}

const enhance = compose(
    connect(
        // Map redux state to component props
        ({ translate: { locale } }) => ({ locale })
    )
)

export default enhance(Translate);

