import firebase from 'firebase/app'
import 'firebase/database';
import 'firebase/auth'
import 'firebase/firestore' 
import { createStore, applyMiddleware, compose } from 'redux'
import { createFirestoreInstance} from 'redux-firestore'
import thunk from 'redux-thunk';
import { rootReducer } from './reducer'

import {
    firebaseIdTokenReceived,
    firebaseUserSignIn,
    firebaseUserSignOut
} from './action/firebaseAuth'

const fbConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID
};

// react-redux-firebase config
const rrfConfig = {};

// Initialize firebase instance
firebase.initializeApp(fbConfig);

firebase.firestore();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Create store with reducers and initial state
const initialState = {};
const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(thunk)));

const rrfProps = {
    firebase,
    config: rrfConfig,
    dispatch: store.dispatch,
    createFirestoreInstance
};

//Listen for auth state change event and update store
firebase.auth().onAuthStateChanged(user => {
    if (user) {
        store.dispatch(firebaseUserSignIn(user));
        user.getIdToken(false).then((idToken) => {
            store.dispatch(firebaseIdTokenReceived(idToken))
        });
    } else {
        store.dispatch(firebaseUserSignOut());
    }
});

export { store, rrfProps }
