import {
    ACTION_PROFILE_USER_SELECT,
    ACTION_NETWORK_INTENT_INVITATION
} from './type'

import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'

import { channelCreate } from '../../util/channel'
import { channelSelect } from './channelOps'

export function userProfileSelect(user_id, activate_side_bar = true) {
    return {
        type: ACTION_PROFILE_USER_SELECT,
        user_id,
        activate_side_bar
    }
}

export function intentInvitation(intent) {
    return {
        type: ACTION_NETWORK_INTENT_INVITATION,
        intent
    }
}

export function intentInvitationFulfill(invitedUserId) {
    return function (dispatch, getState) {

        const intent = getState().userProfile?.intent_invitation;
        const currentUserId = firebase.auth().currentUser.uid;

        dispatch(intentInvitation(null))

        if (typeof currentUserId !== "string") {
            return Promise.resolve();
        }

        let invitation = {
            invited_user_id: invitedUserId,
            owner_id: currentUserId,
            status: "pending"
        }
        if (typeof intent.channelId === "string") {
            invitation.channel_id = intent.channelId
        }
        else if (typeof intent.caseId === "string") {
            invitation.case_id = intent.caseId
        }
        else {
            return Promise.resolve();
        }

        return firebase.ref("/invitation").push(invitation);
    }
}

export function intentInvitationInstantFulfill(invitedUserId) {
    return function (dispatch, getState) {

        const intent = getState().userProfile?.intent_invitation;
        const currentUserId = firebase.auth().currentUser.uid;

        dispatch(intentInvitation(null))

        if (typeof currentUserId !== "string") {
            return Promise.resolve();
        }
        if (typeof intent.channelId !== "string") {
            return Promise.resolve();
        }

        return firebase.ref(`/channel/${intent.channelId}/members/${invitedUserId}`).push({
            read: true,
            write: true
        });
    }
}

export function invitationConnect(invitedUserId) {
    return function (dispatch, getState) {

        const currentUserId = firebase.auth().currentUser.uid;

        if (typeof currentUserId !== "string") {
            return Promise.resolve();
        }

        return channelCreate("", "", "private_dm").then((channelId) => {

            if (typeof channelId !== "string") {
                return Promise.resolve();
            }

            dispatch(channelSelect(channelId));

            const invitation = {
                invited_user_id: invitedUserId,
                owner_id: currentUserId,
                status: "pending",
                channel_id: channelId
            }

            return firebase.ref("/invitation").push(invitation).then(() => {
                return firebase.ref(`/user_ops/${currentUserId}/channel_private/${channelId}/user_id`).set(invitedUserId)
            });

        })
    }
}
