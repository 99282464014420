import {
    ACTION_MYNETWORK_USER_SELECT,
    ACTION_MYNETWORK_CONTACT_FETCH_REQUEST,
    ACTION_MYNETWORK_CONTACT_FETCH_RESULT,
    ACTION_MYNETWORK_USER_SEARCH_REQUEST,
    ACTION_MYNETWORK_USER_SEARCH_RESULT,
    ACTION_GLOBALNETWORK_SET_ACTIVE
} from "../action/type";

function userSearch(state = {}, action) {
    switch (action.type) {
        case ACTION_MYNETWORK_USER_SEARCH_REQUEST: {
            return Object.assign({}, state, {
                fetching: true
            });
        }
        case ACTION_MYNETWORK_USER_SEARCH_RESULT: {
            //process elastic search result before inserting into redux
            const processedResult = action?.result?.map((userItem) => {
                return {
                    ...userItem,
                    ...userItem?._source,
                    id: userItem._id
                }
            });
            return Object.assign({}, state, {
                fetching: false,
                result: processedResult,
                updated: action.updated
            });
        }
        default:
            return state
    }
}

function contactFetch(state = {}, action) {
    switch (action.type) {
        case ACTION_MYNETWORK_CONTACT_FETCH_REQUEST: {
            return Object.assign({}, state, {
                fetching: true
            });
        }
        case ACTION_MYNETWORK_CONTACT_FETCH_RESULT: {
            //process elastic search result before inserting into redux
            const processedResult = action?.result?.map((userItem) => {
                return {
                    ...userItem,
                    ...userItem?._source,
                    id: userItem._id
                }
            });
            return Object.assign({}, state, {
                fetching: false,
                result: processedResult,
                updated: action.updated
            });
        }
        default:
            return state
    }
}

function setGlobalNetworkActive(state = {}, action) {
    switch (action.type) {
        case ACTION_GLOBALNETWORK_SET_ACTIVE: {
            return Object.assign({}, state, {
                global_network_active: action.globalNetworkActive
            });
        }
        default:
            return state
    }
}

export default function myNetworkReducer(state = {}, action) {
    switch (action.type) {
        case ACTION_MYNETWORK_USER_SELECT: {
            return Object.assign({}, state, {
                user_ids: action.user_ids
            });
        }
        case ACTION_MYNETWORK_USER_SEARCH_REQUEST:
        case ACTION_MYNETWORK_USER_SEARCH_RESULT: {
            //Nested Object.assign to make sure only the new values are updated
            return Object.assign({}, state, {
                contacts_search: Object.assign({}, state?.user_search, {
                    results: userSearch(state?.['user_search'], action)
                })
            });
        }
        case ACTION_MYNETWORK_CONTACT_FETCH_REQUEST:
        case ACTION_MYNETWORK_CONTACT_FETCH_RESULT:
            return Object.assign({}, state, {
                contacts: Object.assign({}, state?.contacts, {
                    results: contactFetch(state?.['contacts'], action)
                })
            });
        case ACTION_GLOBALNETWORK_SET_ACTIVE: {
            return Object.assign({}, state, {
                global_network_active: action.globalNetworkActive
            });
        }
        default:
            return state;
    }
}
