import stringsCustom from './stringsCustom'
/**
 * Default translation
 * @class
 */
let strings = {
    app_name: process.env.REACT_APP_APPLICATION_NAME || "myLawyer Network",
    ...stringsCustom.und,
};

export const all = { ...strings, ...stringsCustom };

const stringKeys = Object.keys(all).reduce((acc, cur) => {
    acc[cur] = cur;
    return acc;
}, {});

/**
 * @returns {strings}
 */
function keys() {
    return stringKeys;
}

export default keys();
