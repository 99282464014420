import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { useFirebaseConnect } from 'react-redux-firebase'

import FilterByTerm from './FilterByTermNetworkList'
import { strings } from '../../translate'

function MyNetworkList(props) {

    const { userId, contacts } = props;

    useFirebaseConnect([
        `/user_ops/${userId}/contact`
    ])

    return (
        <FilterByTerm contacts={contacts} title={strings.my_network_title} />
    )
}


const enhance = compose(connect(
    ({ firebase: {
        auth: { uid },
        ordered: { user_ops }
    }, }) => ({
        userId: uid,
        contacts: user_ops?.[uid]?.contact
    })
))

export default enhance(MyNetworkList);
