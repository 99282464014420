export default {
    und: {
        main_menu_inbox: "HOME",
        main_menu_bulletin_board: "MESSAGE BOARD",
        main_menu_discussions: "DISCUSSIONS",
        main_menu_matters: "MATTERS",
        login: "Login",
        bulletin_board_category_title: "MESSAGE BOARD",
        bulletin_board_result_subscribed_title: "POSTS IN MY SUBSCRIBED CATEGORIES",
        bulletin_board_result_category_title: "POSTS IN THIS CATEGORY",
        bulletin_board_result_category_help: "Select a category to view posts",
        bulletin_board_result_empty: "Category List Is Empty",
        bulletin_board_post_result_subscription_empty: "No results for your subscriptions. Try adding a new subscription.",
        bulletin_board_post_result_category_empty: "No results in this category",
        bulletin_board_post_help: "Select a post to begin",
        bulletin_board_post_view_forum_button: "View the conversation",
        bulletin_board_post_owner_label: "Posted by",
        channel_select_help: "Select a Discussion to begin",
        my_network_title: "MY NETWORK",
        global_network_title: "GLOBAL NETWORK",
        global_network_search_term_lable: "Search network",
        global_network_specialism_lable: "Specialism",
        user_profile_connect_button: "Connect",
        user_profile_chat_button: "Chat",
        user_profile_invite_button: "Invite",
        user_profile_invite_instant_button: "Add",
        loading: "Loading...",
        channel_highlight_dialog_title: "Set highlight colour",
        case_channel_select_help: "Select a Matter to begin",
        channel_list_private_title: "PRIVATE DISCUSSIONS",
        channel_list_public_title: "PUBLIC DISCUSSIONS",
        channel_list_global_title: "OTHER PUBLIC DISCUSSIONS",
        channel_title_private: "Private Discussion",
        message_input_placeholder: "Type a message",
        case_list_title: "ACTIVE MATTERS",
        dialog_people_default_title: "Members",
        matter_channel_list_option_people: "People",
        matter_channel_list_option_documents: "Documents",
        matter_channel_list_option_settings: "Matter settings",
        matter_channel_list_option_create_channel: "Add discussion",
        matter_settings_dialog_title: "Matter settings",
        matter_create_dialog_title: "Create matter",
        channel_create_dialog_title: "Create discussion",
        channel_delete_dialog_title: "Delete discussion",
        matter_delete_dialog_title: "Delete matter",
        form_button_cancel: "Cancel",
        form_button_save: "Save",
        form_button_send: "Send",
        form_button_delete: "Delete",
        form_button_upgrade_subscription: "Upgrade",
        channel_option_settings: "Discussion settings",
        channel_option_documents: "View documents",
        channel_option_people: "View members",
        channel_option_delete: "Delete",
        channel_settings_dialog_title: "Channel settings",
        inbox_invitation_list_title: "INVITES",
        inbox_channel_to_read_list_title: "TO READ",
        inbox_matter_to_do_list_title: "TO DO",
        dialog_option_accept: "Accept",
        dialog_option_reject: "Reject",
        dialog_option_view_profile: "view profile",
        media_dialog_open_original: "Open",
        form_button_download: "Download",
        matter_accept_dialog_title: "Accept matter",
        plural_label_member: {
            one: "member",
            other: "members"
        },
        profile_field_label_profile_description: "About me",
        profile_field_label_profile_description_work: "My Work",
        profile_field_label_profile_description_client: "What my clients say",
        profile_field_label_profile_description_professional: "Professional & academic",
        network_switch_my_network: "My",
        network_switch_global: "Global",
        subscription_upgrade_dialog_title: "Subscription",
        subscription_my_current: "Plan purchased on this webapp",
        video_meeting_list_title: "VIDEO MEETINGS",
        video_delete_meeting_title: "Delete this scheduled meeting",
        video_delete_meeting_description: "Are you sure you want to delete this meeting?  This action cannot be undone.",
        video_only_owner_can_add_participants: "Only the meeting owner can manage and view participants."

    },
    fr: {
        main_menu_home: "ACCUEIL",
    }
}
