/**
 * This Theme will override and extend the default Material-UI theme
 * See https://material-ui.com/customization/default-theme/
 */

const color = {
    background: '#ffffff',
    primary: '#3d5a80', //steel-blue
    primaryVariant: '#98c1d9', //light-blue
    secondary: '#ee6c4d', //orange
    secondaryVariant: '#bd5d4a', //dark-orange
    text: '#293241', //dark-steel-blue
    textVariant: '#a7bdca', //light-steel-blue
    textWithPrimary: '#ffffff', //white
    textVariantBackground: '#e0fbfc', //very-light-blue
    colorPrimaryDark: '#a7bdca',
    textOnSecondary: '#ffffff',
    colorSecondaryDisabled: '#dde6eb', //light-grey
    colorPrimaryLight: '#e0fbfc',
    navbarBackground: '#3d5a80',
    textWithNavbar: '#ffffff',
    dialogBackground: '#ffffff',
    textOnDialog: '#3d4f62'
};

export default {
    palette: {
        text: {
            primary: color.text,
            secondary: color.textVariant
        },
        secondary: {
            light: color.secondaryVariant,
            main: color.secondary,
        }
    },
    typography: {
        fontFamily: '\'Lato\', sans-serif'
    },
    shape: {
        borderRadius: 0
    },
    overrides: {
        MuiDialogTitle: {
            root: {
                backgroundColor: color.dialogBackground,
                color: color.textOnDialog
            }
        },
        MuiDialogContent: {
            root: {
                backgroundColor: color.dialogBackground,
                color: color.textOnDialog,
                "& input, & label, & p": {
                    color: color.textOnDialog
                }
            }
        },
        MuiDialogActions: {
            root: {
                backgroundColor: color.dialogBackground,
                color: color.textOnDialog
            }
        }
    },
    mylawyer: {
        colors: color
    }
};
