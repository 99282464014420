import React, { Suspense, lazy } from 'react';
import clsx from 'clsx';
import { compose } from 'redux'
import { connect } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles';
import { Paper, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { disableAll } from '../../redux/action/appOps'

import { all as strings } from '../../translate/strings';

const PaymentMethods = lazy(() => import('../Payment/PaymentMethods'));
const ProfileEdit = lazy(() => import('../Profile/ProfileEdit'));
const BillingProducts = lazy(() => import('../Billing/BillingProducts'));
const MyPostList = lazy(() => import('../BulletinBoard/MyPostList'));
const InviteContact = lazy(() => import('../Invite/InviteContact'));

const paneLibrary = [
    {
        id: "payment_methods", label: "Payment methods"
    },
    {
        id: "profile_edit", label: "Edit profile"
    },
    {
        id: "my_subscriptions", label: "My subscriptions"
    },
    {
        id: "my_posts", label: "My posts"
    },
    {
        id: "invite_contacts", label: "Invite contacts"
    }
];

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        top: '0px',
        height: '100%',
        top: '-10px',
        marginLeft: '60px',
        zIndex: '15'
    },
    rootOpen: {
        marginLeft: '207px'
    },
    rootClosed: {

    },
    blade: {
        width: 320,
        minHeight: '100%',
        backgroundColor: theme.mylawyer.colors.dialogBackground,
        color: theme.mylawyer.colors.textOnDialog,
        position: 'absolute',
        zIndex: 5,
        padding: '5px 5px 5px 10px'
    },
    closeButtonArea: {
        display: 'flex',
        justifyContent: 'flex-end',
        position: 'absolute',
        width: '100%',
        right: '5px'
    },
    headerArea: {
        width: '100%',
        backgroundColor: theme.mylawyer.colors.primary,
        color: theme.mylawyer.colors.textWithPrimary,
        padding: '10px 20px',
        boxSizing: 'border-box',
        textTransform: 'uppercase',
        marginBottom: '20px',
        fontWeight: 'bold'
    },
    instructionArea: {
        marginBottom: '30px',
        padding: '0px 20px'
    },
    buttonColor: {
        color: theme.mylawyer.colors.textWithPrimary
    }
}));

function SideRouter(props) {

    const classes = useStyles();
    const { dispatch, side_menu } = props;

    //Create panes lazily
    function _createPane(type) {
        switch(type) {
            case 'payment_methods': {
                return (<PaymentMethods key="payment_methods"/>)
            }
            case 'profile_edit': {
                return (<ProfileEdit key="profile_edit"/>)
            }
            case 'my_subscriptions': {
                return (<BillingProducts key="my_subscription"/>)
            }
            case 'my_posts': {
                return (<MyPostList key="my_posts"/>)
            }
            case 'invite_contacts': {
                return (<InviteContact key="invite_contacts"/>)
            }
        }
        return null;
    }

    function _createHeader(type) {
        switch(type) {
            case 'payment_methods': {
                return {
                    title: 'Payment methods',
                    instruction: 'Add or edit payment methods which can be used with subscriptions.'
                }
            }
            case 'profile_edit': {
                return {
                    title: 'Edit profile',
                    instruction: 'Manage your profile which is displayed on the app and marketing website if you have a relevant subscription.',
                    bladeWidth: 480
                }
            }
            case 'my_subscriptions': {
                return {
                    title: 'My subscriptions',
                    instruction: `Your paid for subscriptions which give you an enhanced experience across all ${strings.app_name || 'myLawyer Network'} apps.`
                }
            }
            case 'my_posts': {
                return {
                    title: 'My posts',
                    instruction: 'Your draft and approved posts appear here.'
                }
            }
            case 'invite_contacts': {
                return {
                    title: 'Invite contacts',
                    instruction: 'You can invite clients, colleagues and other contacts here.'
                }
            }
        }
        return null;
    }

    function _handleCloseClick() {
        dispatch(disableAll());
    }

    let bladeWidth=320;

    let displayItems = paneLibrary.reduce((acc, cur, idx) => {
        if (side_menu && cur.id in side_menu && side_menu[cur.id]) {
            let text = _createHeader(cur.id);
            if (text?.title) {
                acc.push(<div className={classes.headerArea} key={cur.id + '_title'}><Typography component="h3">{text.title}</Typography></div>)
            }
            if (text?.instruction) {
                acc.push(<div className={classes.instructionArea} key={cur.id + '_instruct'}><Typography>{text.instruction}</Typography></div>)
            }
            if (text?.bladeWidth) {
                bladeWidth = text.bladeWidth
            } else {
                bladeWidth = 320;
            }
            let pane = _createPane(cur.id);
            acc.push(pane)
        }
        return acc;
    }, [])

    let paper = null;
    if (displayItems?.length > 0) {
        paper = (
            <Paper elevation={1} variant="outlined" className={classes.blade} style={{width: bladeWidth}}>
                <div className={classes.closeButtonArea}>
                    <IconButton className={classes.buttonColor} aria-label="close" onClick={_handleCloseClick}>
                        <CloseIcon/>
                    </IconButton>
                </div>
                {displayItems}
            </Paper>
        )
    }

    return (
        <div className={clsx(classes.root, {
            [classes.rootOpen]: side_menu?.open,
            [classes.rootClosed]: !side_menu?.open,
          })}>
            <Suspense fallback={<div>Loading...</div>}>
                {paper}
            </Suspense>
        </div>
    )
}

const enhance = compose(
    connect(
        // Map redux state to component props
        ({
            appOps: { side_menu }
        }) => ({
            side_menu
        })
    )
)

export default enhance(SideRouter);
