import React, { useEffect, useState } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles';

import throttle from 'lodash.throttle'

import { TextField } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';

import GooglePlaceResultItem from './GooglePlaceResultItem'

const autocompleteService = { current: null };

const GOOGLE_PLACES_SCRIPT_ELEMENT_ID = "google-places-script";
const GOOGLE_PLACES_MAP_ELEMENT_ID = "google-places-map";

const useStyles = makeStyles(theme => ({
    root: {
        color: theme.mylawyer.colors.textOnDialog
    }
}));

function loadGooglePlacesScript() {
    const scriptElement = document.querySelector(`#${GOOGLE_PLACES_SCRIPT_ELEMENT_ID}`);
    if (!scriptElement) {
        const position = document.querySelector('head');
        if (!position) {
            return;
        }
        const src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_PLACES_API_KEY}&libraries=places`;
        const script = document.createElement('script');
        script.setAttribute('async', '');
        script.setAttribute('id', GOOGLE_PLACES_SCRIPT_ELEMENT_ID);
        script.src = src;
        position.appendChild(script);
    }
}

function loadGooglePlacesSession() {
    if (!autocompleteService.current && window.google) {
        autocompleteService.sessionToken = new window.google.maps.places.AutocompleteSessionToken();
        autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.places && window.google) {
        let map = document.querySelector(`#${GOOGLE_PLACES_MAP_ELEMENT_ID}`);
        if (!map) {
            const mapElement = document.createElement('div');
            mapElement.setAttribute('id', GOOGLE_PLACES_MAP_ELEMENT_ID);
            map = new window.google.maps.Map(mapElement);
        }
        autocompleteService.places = new window.google.maps.places.PlacesService(map);
    }
}

const fetchGooglePlacePredictions = throttle((event, callback) => {
    if (!autocompleteService.current) {
        console.warn("google place predictions service missing")
        return;
    }
    if (typeof event.target.value !== "string" || event.target.value.length <= 0) {
        return;
    }
    //limit search results to cities
    //https://developers.google.com/places/supported_types#table3
    const request = {
        input: event.target.value,
        sessionToken: autocompleteService.sessionToken,
        types: ["(cities)"]
    }
    autocompleteService.current.getPlacePredictions(request, callback)
}, 200)

const fetchGooglePlaceDetails = throttle((placeId, callback) => {
    if (!autocompleteService.places) {
        console.warn("google places service missing")
        return;
    }
    if (typeof placeId !== "string" || placeId <= 0) {
        return;
    }
    var request = {
        placeId,
        fields: ['place_id', 'geometry', 'address_component'],
        sessionToken: autocompleteService.sessionToken
    };
    autocompleteService.places.getDetails(request, callback)
}, 200)

function GoogleLocation(props) {
    const classes = useStyles();

    const { onChange } = props;

    const [googlePlacesResults, setGooglePlacesResults] = useState([]);

    useEffect(() => {
        loadGooglePlacesScript();
        loadGooglePlacesSession();
    })

    function handleAutocompleteChange(event, value, reason) {
        if (reason === "select-option") {
            fetchGooglePlaceDetails(value.place_id, (place, status) => {
                if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                    onChange({
                        ...value,
                        ...place
                    });
                }
                else {
                    onChange(null);
                }
            });
        }
        else if (reason === "clear") {
            onChange(null);
        }
    }

    function handleTextFieldChange(event) {
        event.persist();
        fetchGooglePlacePredictions(event, (results) => {
            setGooglePlacesResults(results || []);
        })
    }

    return (
        <Autocomplete
            id="google-place-select"
            style={{ minWidth: 200, backgroundColor: '#ffffff', color: '#000000' }}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
            options={googlePlacesResults}
            autoComplete
            includeInputInList
            onChange={handleAutocompleteChange}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Location"
                    variant="outlined"
                    fullWidth
                    onChange={handleTextFieldChange}
                    classes={{ root: classes.root }}
                />
            )}
            renderOption={
                (option) => {
                    return <GooglePlaceResultItem option={option} />
                }}
        />
    )
}

export default GoogleLocation;

export function getAddressFromPlace(place) {
    //get address from google place address components
    //https://developers.google.com/places/web-service/supported_types
    if (!place) {
        return null;
    }
    let location = {};
    if (Array.isArray(place.address_components)) {
        location.address = {};
        place.address_components.forEach((component) => {
            if (component.types.includes("locality")) {
                location.address.city = component.long_name;
            }
            else if (component.types.includes("administrative_area_level_1")) {
                location.address.region = component.long_name;
            }
            else if (component.types.includes("country")) {
                location.address.country = component.long_name;
                location.address.countryCode = component.short_name;
            }
        });
    }
    if (place.geometry) {
        location.geolocation = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng()
        }
    }
    return location;
}
