import {
    ACTION_APP_OPS_SIDEMENU_DRAWER_OPEN,
    ACTION_APP_OPS_SIDEMENU_PAYMENT_METHODS_ACTIVE,
    ACTION_APP_OPS_SIDEMENU_PROFILE_EDIT_ACTIVE,
    ACTION_APP_OPS_SIDEMENU_INVITE_CONTACTS_ACTIVE,
    ACTION_APP_OPS_SIDEMENU_MY_SUBSCRIPTIONS_ACTIVE,
    ACTION_APP_OPS_SIDEMENU_MY_POSTS_ACTIVE,
    ACTION_APP_OPS_SIDEMENU_POST_CREATE,
    ACTION_APP_OPS_SIDEMENU_DISABLE_ALL,
    ACTION_APP_OPS_DIALOG_SET_PASSWORD
} from "../action/type";


export default function appOpsReducer(state = {}, action) {
    switch (action.type) {
        case ACTION_APP_OPS_SIDEMENU_DRAWER_OPEN: {
            return Object.assign({}, state, {
                side_menu: Object.assign({}, state?.side_menu, {
                    open: action.open
                })
            });
        }
        case ACTION_APP_OPS_SIDEMENU_PAYMENT_METHODS_ACTIVE: {
            return Object.assign({}, state, {
                side_menu: Object.assign({}, state?.side_menu, {
                    payment_methods: action.payment_methods
                })
            });
        }
        case ACTION_APP_OPS_SIDEMENU_PROFILE_EDIT_ACTIVE: {
            return Object.assign({}, state, {
                side_menu: Object.assign({}, state?.side_menu, {
                    profile_edit: action.profile_edit
                })
            });
        }
        case ACTION_APP_OPS_SIDEMENU_INVITE_CONTACTS_ACTIVE: {
            return Object.assign({}, state, {
                side_menu: Object.assign({}, state?.side_menu, {
                    invite_contacts: action.invite_contacts
                })
            });
        }
        case ACTION_APP_OPS_SIDEMENU_MY_SUBSCRIPTIONS_ACTIVE: {
            return Object.assign({}, state, {
                side_menu: Object.assign({}, state?.side_menu, {
                    my_subscriptions: action.my_subscriptions
                })
            });
        }
        case ACTION_APP_OPS_SIDEMENU_MY_POSTS_ACTIVE: {
            return Object.assign({}, state, {
                side_menu: Object.assign({}, state?.side_menu, {
                    my_posts: action.my_posts
                })
            });
        }
        case ACTION_APP_OPS_SIDEMENU_POST_CREATE: {
            return Object.assign({}, state, {
                side_menu: Object.assign({}, state?.side_menu, {
                    post_create: action.post_create,
                    my_posts: action.my_posts
                })
            });
        }
        case ACTION_APP_OPS_SIDEMENU_DISABLE_ALL: {
            return Object.assign({}, state, {
                side_menu: Object.assign({}, state?.side_menu, {
                    payment_methods: false,
                    profile_edit: false,
                    my_subscriptions: false,
                    my_posts: false,
                    invite_contacts: false

                })
            });
        }
        case ACTION_APP_OPS_DIALOG_SET_PASSWORD: {
            return Object.assign({}, state, {
                action_dialog: Object.assign({}, state?.action_dialog, {
                    password_open: action.password_open
                })
            });
        }
        default:
            return state;
    }
}
