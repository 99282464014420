/**
 * This Theme will override and extend the default Material-UI theme
 * See https://material-ui.com/customization/default-theme/
 */

const color = {
    background: '#ffffff',
    primary: '#533a71', //aubergine
    primaryVariant: '#6184d8', //blue
    secondary: '#50c5b7', //sea-green
    secondaryVariant: '#9cec5b', //alpine-green
    text: '#1c1427', //almost-black
    textVariant: '#50c5b7', //very-dark
    textWithPrimary: '#ffffff', //white
    textVariantBackground: '#f3eff7', //very-light-grey
    colorPrimaryDark: '#412d58',
    textOnSecondary: '#ffffff',
    colorSecondaryDisabled: '#d6dfdd', //light-grey
    colorPrimaryLight: '#e7ecf3',
    navbarBackground: '#533a71',
    textWithNavbar: '#ffffff',
    dialogBackground: '#f3eff7',
    textOnDialog: '#1c1427'
};

export default {
    palette: {
        text: {
            primary: color.text,
            secondary: color.textVariant
        },
        secondary: {
            light: color.secondaryVariant,
            main: color.secondary,
        }
    },
    typography: {
        fontFamily: '\'Lato\', sans-serif'
    },
    shape: {
        borderRadius: 0
    },
    overrides: {
        MuiDialogTitle: {
            root: {
                backgroundColor: color.dialogBackground,
                color: color.textOnDialog
            }
        },
        MuiDialogContent: {
            root: {
                backgroundColor: color.dialogBackground,
                color: color.textOnDialog,
                "& input, & label, & p": {
                    color: color.textOnDialog
                }
            }
        },
        MuiDialogActions: {
            root: {
                backgroundColor: color.dialogBackground,
                color: color.textOnDialog
            }
        }
    },
    mylawyer: {
        colors: color
    }
};
