import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux'
import { useFirebaseConnect, isLoaded } from 'react-redux-firebase'
import { makeStyles } from '@material-ui/core/styles';

import { firmLogoUrl } from '../util/image'
import myLawyerBanner from '../logo.svg';
import myLawyerRoundel from '../roundel.svg';
import myBarristerBanner from '../banner_dark_mybarrister.png';
import myBarristerRoundel from '../roundel_mybarrister.png';
import { all as strings } from '../translate/strings';

const useStyles = makeStyles((theme) => ({
    logo: {
        padding: '10px',
        maxHeight: '100%',
        maxWidth: '100%',
        display: 'flex'
    }
}));


function Logo(props) {
    const { user_id, user_firm, firm_id, open } = props
    const classes = useStyles();

    useFirebaseConnect([
        {
            path: `/user_ops/${user_id}/firm_associated`,
            storeAs: `user_firm`
        }
    ])

    let logo;
    if (isLoaded(user_firm) && typeof firm_id === "string") {
        logo = (<img src={firmLogoUrl(firm_id)} alt="Logo" className={classes.logo} />)
    } else if(process.env.REACT_APP_PROJECT_FLAVOUR === 'MYBARRISTER') {
        logo = (<img src={open ? myBarristerBanner : myBarristerRoundel} alt={strings.app_name} className={classes.logo} />)
    } else {
        logo = (<img src={open ? myLawyerBanner : myLawyerRoundel} alt={strings.app_name} className={classes.logo} />)
    }

    return (
        <div>{logo}</div>
    );
}

const enhance = compose(
    connect(
        ({
            firebase: { auth: { uid }, data: { user_firm } },
        }) => ({
            user_id: uid,
            user_firm,
            firm_id: Object.keys(user_firm || {})[0],
        })
    )
)

export default enhance(Logo);
