import React from 'react';
import clsx from 'clsx';
import { compose } from 'redux'
import { connect } from 'react-redux'
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ForumIcon from '@material-ui/icons/Forum';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HelpIcon from '@material-ui/icons/Help';
import InfoIcon from '@material-ui/icons/Info';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { disableAll, setPaymentMethods, setProfileEdit, setMySubscriptions, setMyPosts, setDrawerOpen, setInviteContacts, setPasswordDialogOpen } from '../../redux/action/appOps'

import firebase from 'firebase/app'
import 'firebase/auth'

const drawerWidthClosed = 75;
const drawerWidthOpen = 220;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  roundelArea: {
    padding: '0px 10px 0px 5px',
    backgroundColor: theme.mylawyer.colors.primary,
    transition: theme.transitions.create('padding', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidthClosed,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidthOpen,
    backgroundColor: theme.mylawyer.colors.background,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    backgroundColor: theme.mylawyer.colors.background,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toggleArea: {
    display: 'flex',
    justifyContent: 'center'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  iconColor: {
    color: theme.mylawyer.colors.text
  },
  textColor: {
    color: theme.mylawyer.colors.text
  }
}));

function PrimarySideMenu(props) {
  const { container, dispatch, side_menu } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(setDrawerOpen(!open));
  };

  const _handleButtonClick = (e) => {
    dispatch(disableAll());
    let id = e.currentTarget?.dataset?.id;
    switch(id) {
      case 'payment_methods': {
        let active = side_menu?.payment_methods || false;
        dispatch(setPaymentMethods(!active));
        break;
      }
      case 'my_subscriptions': {
        let active = side_menu?.my_subscriptions || false;
        dispatch(setMySubscriptions(!active));
        break;
      }
      case 'my_posts': {
        let active = side_menu?.my_posts || false;
        dispatch(setMyPosts(!active));
        break;
      }
      case 'profile_edit': {
        let active = side_menu?.profile_edit || false;
        dispatch(setProfileEdit(!active));
        break;
      }
      case 'invite_contacts': {
        let active = side_menu?.invite_contacts || false;
        dispatch(setInviteContacts(!active));
        break;
      }
      case 'logout': {
        firebase.auth().signOut();
        break;
      }
    }

  }

  const drawer = (
    <div>
      <List>
        {[
          {
            text: 'Invite contacts',
            key: 'invite_contacts',
            icon: (<InboxIcon/>)
          },{
            text: 'My profile',
            key: 'profile_edit',
            icon: (<AccountBoxIcon/>)
          },{
            text: 'My posts',
            key: 'my_posts',
            icon: (<ForumIcon/>)
          },{
            text: 'Payment methods',
            key: 'payment_methods',
            icon: (<CreditCardIcon/>)
          },{
            text: 'My subscriptions',
            key: 'my_subscriptions',
            icon: (<CardMembershipIcon/>)
          },{
            text: 'Logout',
            key: 'logout',
            icon: (<ExitToAppIcon/>)
          }].map((item, index) => (
          <ListItem button key={item.key} data-id={item.key} onClick={_handleButtonClick}>
            <ListItemIcon className={classes.iconColor}>{item.icon}</ListItemIcon>
            <ListItemText className={classes.textColor} primary={item.text} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {[{
            text: 'Help',
            key: 'help',
            icon: (<HelpIcon />),
            link: 'https://www.mylawyernetwork.com/help'
          },{
            text: 'About',
            key: 'about',
            icon: (<InfoIcon />),
            link: 'https://www.mylawyernetwork.com/about'
          }].map((item, index) => (
          <a href={item.link} target="_blank" key={item.key}>
            <ListItem button key={item.key} data-id={item.key}>
              <ListItemIcon className={classes.iconColor}>{item.icon}</ListItemIcon>
              <ListItemText className={classes.textColor} primary={item.text} />
            </ListItem>
          </a>
        ))}
      </List>
    </div>
  );

  return (
    <div className={classes.root}>
      <nav className={classes.drawer} aria-label="App additional actions">
        <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }),
            }}
          >
          <Divider />
          <div className={classes.toggleArea}>
            <IconButton className={classes.iconColor} onClick={handleDrawerToggle}>
              { open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </div>
          <Divider />
          { drawer }
        </Drawer>
      </nav>
    </div>
  );
}


const enhance = compose(
  connect(
      // Map redux state to component props
      ({
          appOps: { side_menu }
      }) => ({
          side_menu
      })
  )
)

export default enhance(PrimarySideMenu);
