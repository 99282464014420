import {
    ACTION_BULLETIN_BOARD_CATEGORY_SELECT,
    ACTION_BULLETIN_BOARD_POST_SEARCH_REQUEST,
    ACTION_BULLETIN_BOARD_POST_SEARCH_RESULT,
    ACTION_BULLETIN_BOARD_POST_SELECT,
    ACTION_BULLETIN_BOARD_FORUM_SELECT
} from "../action/type";

function postSearch(state = {}, action) {
    switch (action.type) {
        case ACTION_BULLETIN_BOARD_POST_SEARCH_REQUEST: {
            return Object.assign({}, state, {
                fetching: true
            });
        }
        case ACTION_BULLETIN_BOARD_POST_SEARCH_RESULT: {
            //process elastic search result before inserting into redux
            const processedResult = action?.result?.map((postItem) => {
                return {
                    ...postItem,
                    ...postItem?._source,
                    id: postItem._id
                }
            });
            return Object.assign({}, state, {
                fetching: false,
                result: processedResult,
                updated: action.updated
            });
        }
        default:
            return state
    }
}

export default function bulletinBoardReducer(state = {}, action) {
    switch (action.type) {
        case ACTION_BULLETIN_BOARD_CATEGORY_SELECT: {
            return Object.assign({}, state, {
                category_id: action.category_id
            });
        }
        case ACTION_BULLETIN_BOARD_POST_SEARCH_REQUEST:
        case ACTION_BULLETIN_BOARD_POST_SEARCH_RESULT: {
            //Nested Object.assign to make sure only the new values are updated
            return Object.assign({}, state, {
                post_search: Object.assign({}, state?.post_search, {
                    [action.key]: postSearch(state?.['post_search']?.[action.key], action)
                })
            });
        }
        case ACTION_BULLETIN_BOARD_POST_SELECT: {
            return Object.assign({}, state, {
                post_id: action.post_id
            });
        }
        case ACTION_BULLETIN_BOARD_FORUM_SELECT: {
            return Object.assign({}, state, {
                forum_id: action.forum_id
            });
        }
        default:
            return state;
    }
}
