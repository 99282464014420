import {
    ACTION_CHANNEL_SELECT,
    ACTION_CHANNEL_OPS_MORE,
    ACTION_MESSAGE_MORE,
} from '../action/type'

const initialState = {
    channel_page_size: 10,
    message_page_size: 10
};

export default function channelOpsReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_CHANNEL_SELECT: {
            return Object.assign({}, state, {
                channel_id: action.channel_id,
                message_page_size: initialState.message_page_size
            });
        }
        case ACTION_CHANNEL_OPS_MORE: {
            return Object.assign({}, state, {
                channel_page_size: state.channel_page_size + 10
            });
        }
        case ACTION_MESSAGE_MORE: {
            return Object.assign({}, state, {
                message_page_size: state.message_page_size + 10
            });
        }
        default:
            return state;
    }
}
