import React from 'react'

import htmlParser from 'html-react-parser';
import HTMLSanitise from '../util/HTMLSanitise';

export default function HTMLParser(props) {

    const { value } = props;

    const clean = HTMLSanitise(value);

    return htmlParser(clean);
}
