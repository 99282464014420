import {
    ACTION_FIREBASE_AUTH_ID_TOKEN_RECEIVED,
    ACTION_FIREBASE_AUTH_SIGN_IN,
    ACTION_FIREBASE_AUTH_SIGN_OUT,
} from '../action/type'

export default function firebaseIdTokenReceived(state = {}, action) {
    switch (action.type) {
        case ACTION_FIREBASE_AUTH_ID_TOKEN_RECEIVED:
            return {
                ...state,
                idToken: action.idToken

            }
        case ACTION_FIREBASE_AUTH_SIGN_IN:
            return {
                ...state,
                user: action.user

            }
        case ACTION_FIREBASE_AUTH_SIGN_OUT:
            return {
            }
        default:
            return state;
    }
}
