import firebase from 'firebase'
import 'firebase/auth'
import 'firebase/database'

export function setChannelPinned(channelId, channelType, currentPinnedValue = 0) {

    const userId = firebase.auth().currentUser?.uid;
    if (typeof userId !== "string") {
        return Promise.reject();
    }
    if (typeof channelId !== "string") {
        return Promise.reject();
    }

    const pinned = currentPinnedValue < 0 ? 0 : -Date.now();
    const ref = channelType === "private_dm" || channelType === "private"
        ? `/user_ops/${userId}/channel_private/${channelId}/pinned`
        : `/user_ops/${userId}/channel_public/${channelId}/pinned`;
    return firebase.ref(ref).set(pinned);
}

export function setChannelHighlight(channelId, channelType, highlight) {

    const userId = firebase.auth().currentUser?.uid;
    if (typeof userId !== "string") {
        return Promise.reject();
    }
    if (typeof channelId !== "string") {
        return Promise.reject();
    }
    if (typeof channelType !== "string") {
        return Promise.reject();
    }
    if (typeof highlight !== "string") {
        highlight = "";
    }
    const ref = channelType === "private_dm" || channelType === "private"
        ? `/user_ops/${userId}/channel_private/${channelId}/highlight`
        : `/user_ops/${userId}/channel_public/${channelId}/highlight`;
    return firebase.ref(ref).set(highlight);
}

export function channelCreate(title, description, type = "private", case_id = undefined) {

    const currentUser = firebase.auth().currentUser;
    if (!currentUser) {
        return Promise.reject("no user");
    }
    const owner_id = currentUser.uid;
    if (!owner_id) {
        return Promise.reject("no user");
    }

    const ref = `/channel`;
    let channelData = {
        title,
        description,
        owner_id,
        type,
        members: {
            [owner_id]: {
                read: true,
                write: true
            }
        },
        created: firebase.database.ServerValue.TIMESTAMP,
    }
    if (typeof case_id === "string") {
        channelData.type = "case";
        channelData.case_id = case_id;
    }
    const newChannelRef = firebase.ref(ref).push();
    return new Promise((resolve, reject) => {
        newChannelRef.set(channelData, (error) => {
            if (error) {
                //TODO: open dialog?
                console.error("failed to create channel");
                reject();
            }
            else {
                resolve(newChannelRef.key);
            }
        });
    });
}
