import React, { useState, useEffect } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { isLoaded, isEmpty } from 'react-redux-firebase'

import { Translate, strings } from '../../translate'
import ListHeader from '../ListHeader'
import NetworkListItem from './NetworkListItem'
import { fixContact } from '../../util/userOps'

import FilterListIcon from '@material-ui/icons/FilterList';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, LinearProgress, IconButton, TextField, InputAdornment } from "@material-ui/core";


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: '100%',
    },
    buttonColor: {
        color: theme.mylawyer.colors.textWithPrimary
    },
    filterForm: {
        marginTop: 5
    }
}));


function FilterByTermNetworkList(props) {
    const classes = useStyles();

    const { contacts, title, currentUserId } = props;

    const [displayContactFilter, setDisplayContactFilter] = useState(false);
    const [loadingIndicator, setLoadingIndicator] = useState(true);
    const [contatFilterTerm, setContactFilterTerm] = useState("");

    function UserList() {

        setLoadingIndicator(!isLoaded(contacts));

        let listOfContactsToFix = [];

        const contactsComponent = [];
        if (!isEmpty(contacts)) {
            for (let i = 0; i < contacts.length; i++) {
                const userItem = contacts[i];
                //do not include current user in invitation list
                if (userItem.key === currentUserId) {
                    continue;
                }

                if (!userItem.value?.name) {
                    listOfContactsToFix.push(userItem.key);
                }

                //TODO: filter existing members from invitation list?

                //filter by name starts with term
                if (userItem.value?.name?.toLowerCase()?.startsWith(contatFilterTerm?.toLowerCase())) {
                    contactsComponent.push(
                        <NetworkListItem
                            key={userItem.key}
                            member={{ ...userItem.value, id: userItem.key }} />
                    )
                }
            };
        }

        useEffect(() => {
            for (let i = 0; i < listOfContactsToFix.length; i++) {
                fixContact(listOfContactsToFix[i]).catch(console.log);
            }
        });

        if (contactsComponent.length > 0) {
            return contactsComponent
        }
        else {
            const helpMessage = isLoaded(contacts) ? "no results" : strings.loading;
            return <ListItem key="bulletin_board_result_category_help">
                <Translate value={helpMessage} />
            </ListItem>
        }
    }

    const filterButton = (
        <IconButton className={classes.buttonColor} component="span" onClick={() => setDisplayContactFilter(!displayContactFilter)}>
            <FilterListIcon />
        </IconButton>
    );

    //This component must me a variable and not a function. A function will reload onChange and loose focus

    return (
        <List className={classes.root} component="div" aria-label="contacts">
            <ListHeader key="UserListHeader" label={title} button={filterButton} />
            {loadingIndicator && <LinearProgress key="UserListProgress" />}
            {displayContactFilter === true &&
                <TextField
                    id="filter-contacts"
                    label="Search contacts"
                    variant="outlined"
                    className={classes.filterForm}
                    value={contatFilterTerm}
                    onChange={event => setContactFilterTerm(event.target.value)}
                    InputProps={{
                        startAdornment: (<InputAdornment position="start">
                            <FilterListIcon />
                        </InputAdornment>)
                    }}
                    autoFocus
                />}
            <UserList />
        </List>
    )
}


const enhance = compose(
    connect(
        // Map redux state to component props
        ({
            firebase: { auth: { uid } }
        }) => ({
            currentUserId: uid,
        })
    )
)

export default enhance(FilterByTermNetworkList);
