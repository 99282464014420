/**
 * This Theme will override and extend the default Material-UI theme
 * See https://material-ui.com/customization/default-theme/
 */

const color = {
    background: '#edf2f4',
    primary: '#40798c',
    primaryVariant: '#40798c',
    secondary: '#70a9a1',
    secondaryVariant: '#1f363d',
    text: '#2b2d42',
    textVariant: '#40798c',
    textWithPrimary: '#fdfffc',
    textVariantBackground: '#cfe0c3',
    colorPrimaryDark: '#356575',
    textOnSecondary: '#ffffff',
    colorSecondaryDisabled: '#a7ccc5',
    colorPrimaryLight: '#569ab0',
    navbarBackground: '#ef233c', //primary
    textWithNavbar: '#fdfffc', //text-with-primary
    dialogBackground: '#d3dee4',
    textOnDialog: '#2b2d42'
};

export default {
    palette: {
        text: {
            primary: color.text,
            secondary: color.textVariant
        },
        secondary: {
            light: color.secondaryVariant,
            main: color.secondary,
        }
    },
    typography: {
        fontFamily: '\'Lato\', sans-serif'
    },
    shape: {
        borderRadius: 0
    },
    overrides: {
        MuiDialogTitle: {
            root: {
                backgroundColor: color.dialogBackground,
                color: color.textOnDialog
            }
        },
        MuiDialogContent: {
            root: {
                backgroundColor: color.dialogBackground,
                color: color.textOnDialog,
                "& input, & label, & p": {
                    color: color.textOnDialog
                }
            }
        },
        MuiDialogActions: {
            root: {
                backgroundColor: color.dialogBackground,
                color: color.textOnDialog
            }
        }
    },
    mylawyer: {
        colors: color
    }
};
