/**
 * This Theme will override and extend the default Material-UI theme
 * See https://material-ui.com/customization/default-theme/
 */

const color = {
    background: '#ffffff',
    primary: '#e71d36', //red
    primaryVariant: '#e71d36', //red-alt
    secondary: '#ff9f1c', //orange
    secondaryVariant: '#f89000', //dark-orange
    text: '#011627', //dark-steel-blue
    textVariant: '#8f9aa2', //light-steel-blue
    textWithPrimary: '#ffffff', //white
    textVariantBackground: '#def7f5', //very-light-blue
    colorPrimaryDark: '#e9001e',
    textOnSecondary: '#ffffff',
    colorSecondaryDisabled: '#8f9aa2', //light-grey
    colorPrimaryLight: '#ff405e',
    navbarBackground: '#e71d36',
    textWithNavbar: '#ffffff',
    dialogBackground: '#f4f4f5',
    textOnDialog: '#293241'
};

export default {
    palette: {
        text: {
            primary: color.text,
            secondary: color.textVariant
        },
        secondary: {
            light: color.secondaryVariant,
            main: color.secondary,
        }
    },
    typography: {
        fontFamily: '\'Lato\', sans-serif'
    },
    shape: {
        borderRadius: 0
    },
    overrides: {
        MuiDialogTitle: {
            root: {
                backgroundColor: color.dialogBackground,
                color: color.textOnDialog
            }
        },
        MuiDialogContent: {
            root: {
                backgroundColor: color.dialogBackground,
                color: color.textOnDialog,
                "& input, & label, & p": {
                    color: color.textOnDialog
                }
            }
        },
        MuiDialogActions: {
            root: {
                backgroundColor: color.dialogBackground,
                color: color.textOnDialog
            }
        }
    },
    mylawyer: {
        colors: color
    }
};
