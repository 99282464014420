import React, { useState, useEffect, useImperativeHandle } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import clsx from 'clsx'
import { useFirebase, useFirebaseConnect } from 'react-redux-firebase'

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Snackbar from '@material-ui/core/Snackbar';
import AccountCircle from '@material-ui/icons/AccountCircle';
import CloseIcon from '@material-ui/icons/Close';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { setPasswordDialogOpen } from '../../redux/action/appOps'
import { LinearProgress } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      margin: {
        margin: theme.spacing(1),
      },
      withoutLabel: {
        marginTop: theme.spacing(3),
      },
      icon: {
          color: theme.mylawyer.colors.textOnDialog
      },
      snackbar: {
        zIndex: 1301,
        marginLeft: 100
    }
}));

function NamePasswordDialog(props) {
    const classes = useStyles();

    const { dispatch, user_id, user_private, action_dialog } = props;
    const [displayName, setDisplayName] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [progress, setProgress] = useState(false);
    const [result, setResult] = useState('');
    const [snackBarOpen, setSnackBarOpen] = useState(false);

    useFirebaseConnect([
        {
          path: `/user_private/${user_id}`,
          storeAs: `user_private`
        }
      ])

    useEffect(() => {
        setDisplayName(user_private?.name)
    }, [user_private])

    const _handleDisplayName = (e) => {
        setDisplayName(e.target.value)
    }

    const _handlePassword = (e) => {
        setPassword(e.target.value)
    }

    const _handleClickShowPassword = () => {
        setShowPassword(!showPassword);
      };
    
    const _handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const _handleClose = (e) => {
        dispatch(setPasswordDialogOpen(false));
        setPassword('');
        setDisplayName('');
    }
    const _handleSnackBarClose = (e) => {
        setSnackBarOpen(false);
    }

    const firebase = useFirebase();

    const _updateDetails = (e) => {
        //Update the password
        setProgress(true);
        firebase.auth().currentUser.updatePassword(password).then(function() {
            setResult('Password updated');
            setSnackBarOpen(true);
            if (displayName) {
                firebase.ref(`/user_private/${user_id}/name`).set(displayName).then(function() {
                    setResult('Password and details updated');
                    setSnackBarOpen(true);
                    dispatch(setPasswordDialogOpen(false));
                }).catch(function(error){
                    setResult('Error updating name: ' + error.message);
                    setProgress(false);
                })
            } else {
                dispatch(setPasswordDialogOpen(false));
            }
            
        }).catch(function(error) {
            setProgress(false);
            setResult('Error updating password: ' + error.message);
            setSnackBarOpen(true);

        });
    }


    return (
        <div>
            {progress && <LinearProgress/>}
            <Dialog open={action_dialog?.password_open} onClose={_handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Update your details</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    It looks like you have logged in with an email link.  If this is your first time we have set your display name as your email.  Please change this.  Please set your password and update your display name.  
                </DialogContentText>
                <FormControl className={classes.margin}>
                    <InputLabel htmlFor="input-with-icon-adornment">Display Name</InputLabel>
                        <Input
                        id="input-with-icon-adornment"
                        value={displayName}
                        onChange={_handleDisplayName}
                        startAdornment={
                            <InputAdornment className={classes.icon} position="start">
                                <AccountCircle />
                            </InputAdornment>
                        }
                        />
                </FormControl>
                <FormControl className={clsx(classes.margin, classes.textField)}>
                    <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                        <Input
                            id="standard-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            value={password}
                            onChange={_handlePassword}
                            required={true}
                            endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                aria-label="toggle password visibility"
                                onClick={_handleClickShowPassword}
                                onMouseDown={_handleMouseDownPassword}
                                >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                            }
                        />
                </FormControl>
                </DialogContent>
                <DialogActions>
                <Button onClick={_handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={_updateDetails} color="primary">
                    Update
                </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
                }}
                className={classes.snackbar}
                open={snackBarOpen}
                autoHideDuration={6000}
                onClose={_handleSnackBarClose}
                message={result}
                action={
                <React.Fragment>
                    <Button color="secondary" size="small" onClick={_handleSnackBarClose}>
                    CLOSE
                    </Button>
                    <IconButton size="small" aria-label="close" color="inherit" onClick={_handleSnackBarClose}>
                    <CloseIcon fontSize="small" />
                    </IconButton>
                </React.Fragment>
                }
            />
        </div>
    )
}

const enhance = compose(
    connect(
        ({
            firebase: { auth: { uid }, data: {user_private} },
            appOps: { action_dialog }
        }) => ({
            user_id: uid,
            user_private,
            action_dialog
        })
    )
)

export default enhance(NamePasswordDialog);
