/**
 * This Theme will override and extend the default Material-UI theme
 * See https://material-ui.com/customization/default-theme/
 */

const color = {
    background: '#edf2f4', //grey
    primary: '#ef233c', //red
    primaryVariant: '#ef233c', //red-alt
    secondary: '#d80032', //red-sec
    secondaryVariant: '#2b2d42', //dark
    text: '#2b2d42', //dark
    textVariant: '#8d99ae', //sky-grey
    textWithPrimary: '#fdfffc', //white
    textVariantBackground: '#fac0c6', //pink
    colorPrimaryDark: '#d80032', //red-alt
    textOnSecondary: '#ffffff',
    colorSecondaryDisabled: '#75849e', //dark-grey
    colorPrimaryLight: '#ff2958',
    navbarBackground: '#ef233c', //primary
    textWithNavbar: '#fdfffc', //text-with-primary
    dialogBackground: '#e1e3e9',
    textOnDialog: '#2b2d42'
    
};

export default {
    palette: {
        text: {
            primary: color.text,
            secondary: color.textVariant
        },
        secondary: {
            light: color.secondaryVariant,
            main: color.secondary,
        }
    },
    typography: {
        fontFamily: '\'Lato\', sans-serif'
    },
    shape: {
        borderRadius: 0
    },
    overrides: {
        MuiDialogTitle: {
            root: {
                backgroundColor: color.dialogBackground,
                color: color.textOnDialog
            }
        },
        MuiDialogContent: {
            root: {
                backgroundColor: color.dialogBackground,
                color: color.textOnDialog,
                "& input, & label, & p": {
                    color: color.textOnDialog
                }
            }
        },
        MuiDialogActions: {
            root: {
                backgroundColor: color.dialogBackground,
                color: color.textOnDialog
            }
        }
    },
    mylawyer: {
        colors: color
    }
};
