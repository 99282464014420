import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux'
import { useFirebaseConnect, isLoaded } from 'react-redux-firebase'
import { ThemeProvider, useTheme, createMuiTheme } from '@material-ui/core/styles';

//Themes
import SteelBlue from '../themes/SteelBlue'
import RedOrange from '../themes/RedOrange'
import NightBlue from '../themes/NightBlue'
import Aubergine from '../themes/Aubergine';
import BurntOrange from '../themes/BurntOrange';
import RedDark from '../themes/RedDark';
import VolcanoGreen from '../themes/VolcanoGreen';
import DeepPurple from '../themes/DeepPurple';
import GoldBlue from '../themes/GoldBlue';
import GoldRedDark from '../themes/GoldRedDark';

function MyLawFirmThemeProvider(props) {
    const { user_id, firm_theme, children, firm_id, user_firm } = props
    let baseTheme = useTheme();

    let themes = {
      'SteelBlue': SteelBlue, //option1
      'RedOrange': RedOrange, //option2
      'NightBlue': NightBlue, //option3
      'Aubergine': Aubergine, //option4
      'BurntOrange': BurntOrange, //option5
      'RedDark': RedDark, //option6
      'VolcanoGreen': VolcanoGreen, //option7
      'DeepPurple': DeepPurple, //option8
      'GoldBlue': GoldBlue, //option9
      'GoldRedDark': GoldRedDark //option10
    };

    let fonts = {
      'PlayfairDisplay': '\'Playfair Display\', serif'
    }

    useFirebaseConnect([
        {
          path: `/user_ops/${user_id}/firm_associated`,
          storeAs: `user_firm`
        },
        {
          path: `/firm_theme/${firm_id}`,
          storeAs: `firm_theme`
        }
      ])

    const theme = React.useMemo(() => {
      if (isLoaded(firm_theme)) {
        let customThemeName = firm_theme?.palette?.name;
        let customFont = firm_theme?.font;
        if (customThemeName && themes[customThemeName]) {
          let customTheme = themes[customThemeName];
          if (customFont && fonts[customFont]) {
            customTheme.typography.fontFamily = fonts[customFont];
          }
          return createMuiTheme(themes[customThemeName]);
        } else {
          return baseTheme;
        }
    } else {
      return baseTheme;
    }
    }, [firm_id, firm_theme]);
      
  return (
    <ThemeProvider
      theme={theme}
    >
      {children}
    </ThemeProvider>
  );
}

const enhance = compose(
    connect(
      ({
          firebase: { auth: { uid }, data: { user_firm, firm_theme } },
      }) => ({
        user_id: uid,
        user_firm,
        firm_id: user_firm instanceof Object && Object.keys(user_firm).length > 0 ? Object.keys(user_firm)[0] : (typeof user_firm === 'string' || user_firm instanceof String) ? user_firm : 'none',
        firm_theme
      })
    )
  )
  
  export default enhance(MyLawFirmThemeProvider);