import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid'
import HomeIcon from '@material-ui/icons/Home'
import DashboardIcon from '@material-ui/icons/Dashboard'
import ForumIcon from '@material-ui/icons/Forum'
import WorkIcon from '@material-ui/icons/Work'

import MainMenu from './Menu'
import NetworkBladeManager from '../Blade/NetworkBladeManager';
import SideMenu from '../SideMenu/SideMenu';
import SideRouter from '../SideMenu/SideRouter';
import Logo from '../../styles/Logo'

import strings from '../../translate/strings'
import NamePasswordDialog from './NamePasswordDialog';

const Inbox = lazy(() => import('../../pages/Inbox'));
const BulletinBoard = lazy(() => import('../../pages/BulletinBoard'));
const Discussions = lazy(() => import('../../pages/Discussions'));
const Matters = lazy(() => import('../../pages/Matters'));

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.mylawyer.colors.background,
        display: 'flex',
        width: '100%'
    },
    logoArea: {
        maxHeight: 72,
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'flex-end',
        paddingRight: 20,
        "& img": {
            maxHeight: '100%',
            maxWidth: '100%',
            width: '100%'
        }
    }
}))

const menuLibrary = [
    {
        path: "/", label: strings.main_menu_inbox, icon: (<HomeIcon />), route: (<Inbox />)
    },
    {
        path: "/message-board", label: strings.main_menu_bulletin_board, icon: (<DashboardIcon />), route: (<BulletinBoard />)
    },
    {
        path: "/discussions", label: strings.main_menu_discussions, icon: (<ForumIcon />), route: (<Discussions />)
    },
    {
        path: "/matters", label: strings.main_menu_matters, icon: (<WorkIcon />), route: (<Matters />)
    }
];

const routeLibrary = menuLibrary.map(currentMenuItem =>
    (
        <Route
            exact path={currentMenuItem.path}
            key={currentMenuItem.path}>
            {currentMenuItem.route}
        </Route>
    ));

function MenuRouter() {
    const classes = useStyles();
    return (
        <Router>
            <div className={classes.root}>
                <div className="appContent">
                    <div className="appTray left side-menu">
                        <SideMenu />
                        <SideRouter />
                    </div>
                    <div className="appTray middle">
                        <Grid container>
                            <Grid item xs={3} className={classes.logoArea}><Logo open/></Grid>
                            <Grid item xs={9}><MainMenu menu={menuLibrary} /></Grid>
                        </Grid>

                        <Suspense fallback={<div>Loading...</div>}>
                            <Switch>
                                {routeLibrary}
                            </Switch>
                        </Suspense>

                    </div>
                    <div className="appTray right network-blades"><NetworkBladeManager /></div>
                </div>
                <NamePasswordDialog/>
            </div>
        </Router>
    )
}

export default MenuRouter
