import React, { useEffect, useState } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { useFirebaseConnect, isEmpty } from 'react-redux-firebase'

import debounce from 'lodash.debounce'

import { Translate, strings } from '../../translate'
import NetworkListItem from './NetworkListItem'
import { initialFetchGlobalNetwork, searchGlobalNetwork } from '../../util/searchNetwork'
import {
    initialFetchRequest,
    initialFetchResult,
    globalSearchRequest,
    globalSearchResult
} from '../../redux/action/globalNetwork'

import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, LinearProgress, MenuItem } from "@material-ui/core";

import SearchForm from './GlobalNetworkSearchForm';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: '100%',
        paddingTop: '8px',
    },
    searchForm: {
        padding: 5,
        backgroundColor: theme.mylawyer.colors.primary
    },
    scroller: {
        maxHeight: 'calc(100vh - 205px)',
        overflowY: 'scroll'
    }
}));

function GlobalNetworkList(props) {
    const classes = useStyles();

    const { dispatch, specialisms, user_id_state, global_network_search } = props;
    const [searchPhrase, setSearchPhrase] = useState('');
    const [searchSpecialism, setSearchSpecialism] = useState('');
    const [searchLocation, setSearchLocation] = useState('');
    const [searchPageStart, setSearchPageStart] = useState(0);
    const [searchPageSize, setSearchPageSize] = useState(30);
    const [loadingIndicator, setLoadingIndicator] = useState(true);


    let globalUserList;

    useFirebaseConnect([
        '/config/specialisms'
    ])

    useEffect(() => {
        _fetchGlobalNetwork()
    }, [])

    function _fetchGlobalNetwork() {
        dispatch(initialFetchRequest());
        setLoadingIndicator(true);
        initialFetchGlobalNetwork(user_id_state, (user_id_result, hits) => {
            dispatch(initialFetchResult(user_id_result, hits));
            setLoadingIndicator(false);
        }, (user_id_error, error) => {
            dispatch(initialFetchResult(user_id_error, null));
            setLoadingIndicator(false);
        })
    }

    if (isEmpty(global_network_search?.results)) {
        const helpMessage = strings.bulletin_board_post_result_subscription_empty;
        globalUserList = <ListItem key="bulletin_board_result_category_help">
            <Translate value={helpMessage} />
        </ListItem>
    }
    else {
        globalUserList = global_network_search?.results?.result?.map((userItem) => {
            return <NetworkListItem key={userItem.id} member={userItem} />
        });
    }

    const _dispatchGlobalNetworkSearch = () => {
        dispatch(globalSearchRequest(user_id_state, ''));
        setLoadingIndicator(true);
        searchGlobalNetwork(
            user_id_state,
            searchPhrase,
            searchSpecialism,
            searchLocation,
            searchPageStart,
            searchPageSize,
            (user_id_result, hits) => {
                dispatch(globalSearchResult(user_id_result, hits));
                setLoadingIndicator(false);
            }, (user_id_error, error) => {
                dispatch(globalSearchResult(user_id_error, null));
                setLoadingIndicator(false);
            })
    }

    //Search when state changes
    useEffect(() => {
        _dispatchGlobalNetworkSearch()
    }, [searchPhrase, searchSpecialism, searchLocation])

    const _searchGlobalNetworkPhrase = debounce((phrase) => {
        setSearchPhrase(phrase);
        setLoadingIndicator(true);
    }, 1000);

    const _searchGlobalNetworkSpecialism = (specialism) => {
        setSearchSpecialism(specialism);
    }

    const _searchGlobalNetworkLocation = (place) => {
        setSearchLocation(place?.structured_formatting?.main_text || '');
    }

    let specialismOptions = null;
    if (!isEmpty(specialisms?.specialisms)) {
        specialismOptions = specialisms?.specialisms.map((specialismItem) => {
            return <MenuItem
                value={specialismItem.value}
                key={specialismItem.key}>
                {specialismItem.value}
            </MenuItem>
        });
    }

    return (
        <div className={classes.root}>
            <SearchForm
                specialismOptions={specialismOptions}
                onChangeSearchTerm={(term) => { _searchGlobalNetworkPhrase(term) }}
                onChangeSpecialism={(event) => { _searchGlobalNetworkSpecialism(event) }}
                onChangeLocation={(place) => { _searchGlobalNetworkLocation(place) }}
            />
            <List className={classes.scroller} component="div" aria-label="contacts">
                {loadingIndicator && <LinearProgress key="UserListProgress" />}
                {globalUserList}
            </List>
        </div>
    )
}


const enhance = compose(
    connect(
        // Map redux state to component props
        ({
            firebase: { auth, ordered: { config } },
            globalNetwork: { global_network_search }
        }) => ({
            specialisms: config,
            user_id_state: auth.uid,
            global_network_search
        })
    )
)

export default enhance(GlobalNetworkList);
