import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { useFirebaseConnect, isLoaded, isEmpty } from 'react-redux-firebase'

import { Translate, strings } from '../../translate'
import { userImageUrl } from '../../util/image'
import UserProfileConnectButton from './UserProfileConnectButton'
import { userSelect } from '../../redux/action/myNetwork'
import { userProfileSelect } from '../../redux/action/userProfile';
import HTMLParser from '../HTMLParser'

import { makeStyles } from '@material-ui/core/styles';

import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import NotesIcon from '@material-ui/icons/Notes';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import LabelIcon from '@material-ui/icons/Label';
import PlaceIcon from '@material-ui/icons/Place';
import PhoneIcon from '@material-ui/icons/Phone';
import GavelRoundedIcon from '@material-ui/icons/GavelRounded';
import CloseIcon from '@material-ui/icons/Close';

import {
    Grid,
    LinearProgress,
    IconButton,
    List,
    ListItemText,
    ListItemIcon,
    ListItem,
    Avatar,
    Divider
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
    },
    secondary: {
        color: theme.mylawyer.colors.textWithPrimary
    },
    avatar: {
        width: 100,
        height: 100,
        marginTop: 20
    },
    itemTitle: {
        color: theme.mylawyer.colors.textWithPrimary,
        fontSize: '0.875rem'
    },
    itemContent: {
        color: theme.mylawyer.colors.textWithPrimary,
        fontSize: '1rem'
    },
    closeButton: {
        float: 'right',
        color: theme.mylawyer.colors.textWithPrimary
    },
    iconColor: {
        color: theme.mylawyer.colors.secondary
    }
}));

function UserProfile(props) {
    const classes = useStyles();

    const { dispatch, selected_user_id, user_profile, currentUserId, selected_user_contact } = props;

    useFirebaseConnect([
        `/user_public/${selected_user_id}`,
        `/user_ops/${currentUserId}/contact/${selected_user_id}`
    ])

    if (!isLoaded(user_profile)) {
        return (
            <Grid className={classes.root}>
                <LinearProgress />
            </Grid>
        )
    }

    if (isEmpty(user_profile)) {
        return (
            <div className={classes.root}>
                <Translate value={strings.bulletin_board_post_help} />
            </div>
        )
    }

    function flattenUserProfile(userProfile) {
        let flattened = {};
        for (let [key, value] of Object.entries(userProfile)) {
            flattened[value.key] = value.value;
        }
        return flattened;
    }

    let flattened_user_profile = flattenUserProfile(user_profile);

    const specialisms = flattened_user_profile?.profileSpecialisms ? Object.values(flattened_user_profile?.profileSpecialisms).join(', ') : '';
    const locations = flattened_user_profile?.profileLocations ? Object.values(flattened_user_profile?.profileLocations).map(v => {
        let s = v.address?.city + ', ' + v.address?.country;
        return s;
    }).join("\n") : '';

    function onClose() {
        dispatch(userSelect());
        dispatch(userProfileSelect());
    }

    return (
        <Grid className={classes.root}>
            <UserProfileConnectButton user_ops_contact={selected_user_contact} />
            <IconButton className={classes.closeButton} onClick={onClose}>
                <CloseIcon />
            </IconButton>
            <Divider />
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <Avatar className={classes.avatar} alt={flattened_user_profile?.name} src={userImageUrl(selected_user_id)} />
                </Grid>
                <Grid item xs={12} md={8}>
                    <List component="div" aria-label="profile name">
                        <ListItem>
                            <ListItemIcon className={classes.iconColor}>
                                <VideoLabelIcon />
                            </ListItemIcon>
                            <ListItemText primary="Name" secondary={flattened_user_profile?.name} classes={{ primary: classes.itemTitle, secondary: classes.itemContent }} />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon className={classes.iconColor}>
                                <LabelIcon />
                            </ListItemIcon>
                            <ListItemText primary="Specialisms" secondary={specialisms} classes={{ primary: classes.itemTitle, secondary: classes.itemContent }} />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon className={classes.iconColor}>
                                <PlaceIcon />
                            </ListItemIcon>
                            <ListItemText primary="Locations" secondary={locations} classes={{ primary: classes.itemTitle, secondary: classes.itemContent }} />
                        </ListItem>
                    </List>
                </Grid>
            </Grid>

            <List component="div" aria-label="profile about">
                <ListItem>
                    <ListItemIcon className={classes.iconColor}>
                        <NotesIcon />
                    </ListItemIcon>
                    <ListItemText primary="About" secondary={<HTMLParser value={flattened_user_profile?.profile_description ?? ""} />} classes={{ primary: classes.itemTitle, secondary: classes.itemContent }} />
                </ListItem>
            </List>
            <List component="div" aria-label="profile work">
                <ListItem>
                    <ListItemIcon className={classes.iconColor}>
                        <NotesIcon />
                    </ListItemIcon>
                    <ListItemText primary="Work" secondary={<HTMLParser value={flattened_user_profile?.profile_description_work ?? ""} />} classes={{ primary: classes.itemTitle, secondary: classes.itemContent }} />
                </ListItem>
            </List>
            <List component="div" aria-label="profile clients">
                <ListItem>
                    <ListItemIcon className={classes.iconColor}>
                        <ThumbUpIcon />
                    </ListItemIcon>
                    <ListItemText primary="What my clients say" secondary={<HTMLParser value={flattened_user_profile?.profile_description_client ?? ""} />} classes={{ primary: classes.itemTitle, secondary: classes.itemContent }} />
                </ListItem>
            </List>
            <List component="div" aria-label="profile professional academic">
                <ListItem>
                    <ListItemIcon className={classes.iconColor}>
                        <AccountBalanceIcon />
                    </ListItemIcon>
                    <ListItemText primary="Professional &amp; academic" secondary={<HTMLParser value={flattened_user_profile?.profile_description_professional ?? ""} />} classes={{ primary: classes.itemTitle, secondary: classes.itemContent }} />
                </ListItem>
            </List>
            <List component="div" aria-label="phone">
                <ListItem>
                    <ListItemIcon className={classes.iconColor}>
                        <PhoneIcon />
                    </ListItemIcon>
                    <ListItemText primary="Phone" secondary={flattened_user_profile?.contact_phone} classes={{ primary: classes.itemTitle, secondary: classes.itemContent }} />
                </ListItem>
            </List>
            <List component="div" aria-label="skype">
                <ListItem>
                    <ListItemIcon className={classes.iconColor}>
                        <PhoneIcon />
                    </ListItemIcon>
                    <ListItemText primary="Skype" secondary={flattened_user_profile?.contact_skype} classes={{ primary: classes.itemTitle, secondary: classes.itemContent }} />
                </ListItem>
            </List>
            <List component="div" aria-label="is a lawyer">
                <ListItem>
                    <ListItemIcon className={classes.iconColor}>
                        <GavelRoundedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Is a lawyer" secondary={flattened_user_profile?.profile_is_lawyer} classes={{ primary: classes.itemTitle, secondary: classes.itemContent }} />
                </ListItem>
            </List>
        </Grid >
    )
}

const enhance = compose(
    connect(
        // Map redux state to component props
        ({
            firebase: {
                auth: { uid },
                ordered: { user_public },
                data: { user_ops }
            },
            userProfile: { selected_user_id, intent_invitation },
        }) => ({
            currentUserId: uid,
            user_profile: user_public?.[selected_user_id],
            selected_user_id,
            intent_invitation,
            selected_user_contact: user_ops?.[uid]?.contact?.[selected_user_id]
        })
    )
)

export default enhance(UserProfile);
