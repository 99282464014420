/**
 * This Theme will override and extend the default Material-UI theme
 * See https://material-ui.com/customization/default-theme/
 */

const color = {
    background: '#001d32',
    primary: '#b08a00',
    primaryVariant: '#927300',
    secondary: '#e71d36',
    secondaryVariant: '#ffca1c',
    text: '#ffffff',
    textVariant: '#ffca1c',
    textWithPrimary: '#ffffff',
    textVariantBackground: '#a6dbff',
    colorPrimaryDark: '#927300',
    textOnSecondary: '#ffffff',
    colorSecondaryDisabled: '#7196af',
    colorPrimaryLight: '#c79d00',
    navbarBackground: '#b08a00', //primary
    textWithNavbar: '#ffffff', //text-with-primary
    dialogBackground: '#fffaea',
    textOnDialog: '#001d32'
};

export default {
    palette: {
        text: {
            primary: color.text,
            secondary: color.textVariant
        },
        secondary: {
            light: color.secondaryVariant,
            main: color.secondary,
        }
    },
    typography: {
        fontFamily: '\'Lato\', sans-serif'
    },
    shape: {
        borderRadius: 0
    },
    overrides: {
        MuiDialogTitle: {
            root: {
                backgroundColor: color.dialogBackground,
                color: color.textOnDialog
            }
        },
        MuiDialogContent: {
            root: {
                backgroundColor: color.dialogBackground,
                color: color.textOnDialog,
                "& input, & label, & p": {
                    color: color.textOnDialog
                }
            }
        },
        MuiDialogActions: {
            root: {
                backgroundColor: color.dialogBackground,
                color: color.textOnDialog
            }
        }
    },
    mylawyer: {
        colors: color
    }
};
