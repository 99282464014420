import React from 'react';
import FirebaseProvider from './redux/provider';
import { createMuiTheme, StylesProvider, ThemeProvider } from '@material-ui/core/styles';
import { MyLawFirmThemeProvider } from './styles'
import myLawyerTheme from './theme'
import { hot } from 'react-hot-loader/root'
import LoginProvider from './components/LoginProvider.js'
import MainMenuRouter from './components/MainMenu/Router';

const muiTheme = createMuiTheme(myLawyerTheme);

function App() {
  return (
    <FirebaseProvider>
      <StylesProvider>
          <ThemeProvider theme={muiTheme}>
            <MyLawFirmThemeProvider>
                <div className="mainApp">
                  <LoginProvider>
                    <MainMenuRouter />
                  </LoginProvider>
              </div>
              </MyLawFirmThemeProvider>
          </ThemeProvider>
      </StylesProvider>
    </FirebaseProvider>
  );
}

export default process.env.NODE_ENV === "development" ? hot(App) : App
