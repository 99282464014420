
/**
 *
 * @param {string} userId
 * WARNING: This method will return a cached out of date image. Use [/user_public/{userId}/profileImages] instead
 */
export function userImageUrl(userId) {
    return `https://storage.googleapis.com/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_PROFILE}/v2/user_public/${userId}/profile_picture`;
}

/**
 *
 * @param {string} channelId
 * WARNING: This method will return a cached out of date image. Use [/channel/{channelId}/badge] instead
 */
export function channelImageUrl(channelId) {
    return `https://storage.googleapis.com/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_PROFILE}/v2/channel/${channelId}/thumb_badge`;
}

export function categoryImageUrl(category_id) {
    return `https://storage.googleapis.com/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_PROFILE}/v2/bulletinboard/category/${category_id}/thumb_badge.png`;
}

export function caseImageUrl(caseId) {
    return `https://storage.googleapis.com/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_PROFILE}/v2/case/${caseId}/thumb_badge`;
}

export function postImageUrl(postId) {
    return `https://storage.googleapis.com/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_PROFILE}/v2/bulletinboard/post/${postId}/user_banner`;
}

export function postImageThumbUrl(postId) {
    return `https://storage.googleapis.com/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_PROFILE}/v2/bulletinboard/post/${postId}/thumb_banner`;
}

export function firmLogoUrl(firmId) {
    return `https://storage.googleapis.com/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_PROFILE}/v2/firm_public/${firmId}/profile_picture`;
}

export function documentThumbnail(path, idToken) {
    const encodedPath = encodeURIComponent(path);
    return `${process.env.REACT_APP_FUNCTION_URI}/storage/channel/${encodedPath}/128/?token=${idToken}`;
}

export function documentDownload(path, idToken) {
    const encodedPath = encodeURIComponent(path);
    return `${process.env.REACT_APP_FUNCTION_URI}/storage/channel/${encodedPath}/512/?token=${idToken}`;
}
