
export const ACTION_BULLETIN_BOARD_CATEGORY_SELECT = 'ACTION_BULLETIN_BOARD_CATEGORY_SELECT'
export const ACTION_BULLETIN_BOARD_POST_SEARCH_REQUEST = 'ACTION_BULLETIN_BOARD_POST_SEARCH_REQUEST'
export const ACTION_BULLETIN_BOARD_POST_SEARCH_RESULT = 'ACTION_BULLETIN_BOARD_POST_SEARCH_RESULT'
export const ACTION_BULLETIN_BOARD_POST_SELECT = 'ACTION_BULLETIN_BOARD_POST_SELECT'
export const ACTION_BULLETIN_BOARD_FORUM_SELECT = 'ACTION_BULLETIN_BOARD_FORUM_SELECT'

export const ACTION_CHANNEL_SELECT = 'ACTION_CHANNEL_SELECT'
export const ACTION_CHANNEL_OPS_MORE = 'ACTION_CHANNEL_OPS_MORE'
export const ACTION_MESSAGE_MORE = 'ACTION_MESSAGE_MORE'

export const ACTION_ENCRYPT_RECEIVE_MESSAGE = 'ACTION_ENCRYPT_RECEIVE_MESSAGE'

export const ACTION_MYNETWORK_USER_SELECT = 'ACTION_MYNETWORK_USER_SELECT';
export const ACTION_MYNETWORK_CONTACT_FETCH_REQUEST = 'ACTION_MYNETWORK_CONTACT_FETCH_REQUEST';
export const ACTION_MYNETWORK_CONTACT_FETCH_RESULT = 'ACTION_MYNETWORK_CONTACT_FETCH_RESULT';
export const ACTION_MYNETWORK_USER_SEARCH_REQUEST = 'ACTION_MYNETWORK_USER_SEARCH_REQUEST'
export const ACTION_MYNETWORK_USER_SEARCH_RESULT = 'ACTION_MYNETWORK_USER_SEARCH_RESULT'

export const ACTION_GLOBALNETWORK_SET_ACTIVE = 'ACTION_GLOBALNETWORK_SET_ACTIVE'
export const ACTION_GLOBALNETWORK_INITIAL_FETCH_REQUEST = 'ACTION_GLOBALNETWORK_INITIAL_FETCH_REQUEST'
export const ACTION_GLOBALNETWORK_INITIAL_FETCH_RESULT = 'ACTION_GLOBALNETWORK_INITIAL_FETCH_RESULT'
export const ACTION_GLOBALNETWORK_SEARCH_REQUEST = 'ACTION_GLOBALNETWORK_SEARCH_REQUEST'
export const ACTION_GLOBALNETWORK_SEARCH_RESULT = 'ACTION_GLOBALNETWORK_SEARCH_RESULT'

export const ACTION_PROFILE_USER_SELECT = 'ACTION_PROFILE_USER_SELECT';

export const ACTION_APP_OPS_SIDEMENU_DRAWER_OPEN = 'ACTION_APP_OPS_SIDEMENU_DRAWER_OPEN';
export const ACTION_APP_OPS_SIDEMENU_DISABLE_ALL = 'ACTION_APP_OPS_SIDEMENU_DISABLE_ALL';
export const ACTION_APP_OPS_SIDEMENU_PAYMENT_METHODS_ACTIVE = 'ACTION_APPOPS_SIDEMENU_PAYMENT_METHODS_ACTIVE';
export const ACTION_APP_OPS_SIDEMENU_INVITE_CONTACTS_ACTIVE = 'ACTION_APP_OPS_SIDEMENU_INVITE_CONTACTS_ACTIVE';
export const ACTION_APP_OPS_SIDEMENU_PROFILE_EDIT_ACTIVE = 'ACTION_APP_OPS_SIDEMENU_PROFILE_EDIT_ACTIVE';
export const ACTION_APP_OPS_SIDEMENU_MY_SUBSCRIPTIONS_ACTIVE = 'ACTION_APP_OPS_SIDEMENU_MY_SUBSCRIPTIONS_ACTIVE';
export const ACTION_APP_OPS_SIDEMENU_MY_POSTS_ACTIVE = 'ACTION_APP_OPS_SIDEMENU_MY_POSTS_ACTIVE';
export const ACTION_APP_OPS_SIDEMENU_POST_CREATE = 'ACTION_APP_OPS_SIDEMENU_POST_CREATE';
export const ACTION_APP_OPS_DIALOG_SET_PASSWORD = 'ACTION_APP_OPS_DIALOG_SET_PASSWORD';

export const ACTION_BILLING_INITIAL_FETCH_REQUEST = 'ACTION_BILLING_INITIAL_FETCH_REQUEST';
export const ACTION_BILLING_INITIAL_FETCH_RESULT = 'ACTION_BILLING_INITIAL_FETCH_RESULT';
export const ACTION_BILLING_CUSTOMER_FETCH_REQUEST = 'ACTION_BILLING_CUSTOMER_FETCH_REQUEST';
export const ACTION_BILLING_CUSTOMER_FETCH_RESULT = 'ACTION_BILLING_CUSTOMER_FETCH_RESULT';

export const ACTION_CASE_SELECT = 'ACTION_CASE_SELECT'
export const ACTION_CASE_OPS_MORE = 'ACTION_CASE_OPS_MORE'

export const ACTION_FIREBASE_AUTH_SIGN_IN = 'ACTION_FIREBASE_AUTH_SIGN_IN'
export const ACTION_FIREBASE_AUTH_SIGN_OUT = 'ACTION_FIREBASE_AUTH_SIGN_OUT'
export const ACTION_FIREBASE_AUTH_ID_TOKEN_RECEIVED = 'ACTION_FIREBASE_AUTH_ID_TOKEN_RECEIVED'

export const ACTION_NETWORK_INTENT_INVITATION = 'ACTION_NETWORK_INTENT_INVITATION'
