
import sanitizeHtml from 'sanitize-html';

const allowedTags = ['b', 'i', 'ul', 'li', 'br']

export default function (value) {
    return sanitizeHtml(value, {
        allowedTags
    }) || "";
}
