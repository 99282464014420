import {
    ACTION_GLOBALNETWORK_INITIAL_FETCH_REQUEST,
    ACTION_GLOBALNETWORK_INITIAL_FETCH_RESULT,
    ACTION_GLOBALNETWORK_SEARCH_REQUEST,
    ACTION_GLOBALNETWORK_SEARCH_RESULT,
    ACTION_GLOBALNETWORK_SET_ACTIVE
} from './type'

export function setGlobalNetworkActive(globalNetworkActive) {
    return {
        type: ACTION_GLOBALNETWORK_SET_ACTIVE,
        globalNetworkActive
    }
}

export function initialFetchRequest() {
    return {
        type: ACTION_GLOBALNETWORK_INITIAL_FETCH_REQUEST,
    }
}

export function initialFetchResult(user_id, result) {
    return {
        type: ACTION_GLOBALNETWORK_INITIAL_FETCH_RESULT,
        user_id,
        result,
        updated: Date.now()
    }
}

export function globalSearchRequest(phrase, specialisms, locations, pageStart, pageSize) {
    return {
        type: ACTION_GLOBALNETWORK_SEARCH_REQUEST,
        phrase,
        specialisms,
        locations,
        pageStart,
        pageSize
    }
}

export function globalSearchResult(user_id, result) {
    return {
        type: ACTION_GLOBALNETWORK_SEARCH_RESULT,
        user_id,
        result,
        updated: Date.now()
    }
}


