import firebase from 'firebase';
import 'firebase/auth';
import 'firebase/database';

export function fixContact(contactUserId) {
    if (typeof contactUserId !== "string") {
        return Promise.reject("contactUserId invalid");
    }
    const currentUserId = firebase.auth().currentUser.uid;
    if (typeof currentUserId !== "string") {
        return Promise.reject("currentUserId invalid");
    }
    return firebase.database()
        .ref(`/user_public/${contactUserId}`)
        .once("value")
        .then((snapshot) => {
            const name = (snapshot.val() && snapshot.val().name);
            if (typeof name === "string") {
                return firebase.database()
                    .ref(`/user_ops/${currentUserId}/contact/${contactUserId}/name`)
                    .set(name);
            }
            return Promise.reject("user does not have a name " + contactUserId);
        });
}
