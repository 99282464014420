import React, { useEffect, useState } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { useFirebaseConnect, useFirebase, isLoaded, isEmpty } from 'react-redux-firebase'

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ErrorIcon from '@material-ui/icons/Error';
import LockIcon from '@material-ui/icons/Lock';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Button, Card, CardContent, Container, Divider, Dialog, DialogActions,
    DialogTitle, DialogContent, DialogContentText, FormControl, FormControlLabel,
    FormLabel, Grid, IconButton, Input, InputLabel, InputAdornment, Link, ListSubheader,
    MenuItem, RadioGroup, Radio, Select, TextField,  Typography, ListItem, LinearProgress } from "@material-ui/core";

import Logo from '../../styles/Logo';
import { strings } from '../../translate'


const useStyles = makeStyles(theme => ({
    logoArea: {
        margin: '50px 20px'
    },
    logo: {
        width: '100%'
    },
    message: {
        width: '100%',
        marginBottom: '20px',
        color: theme.mylawyer.colors.secondary
    },
    loginField: {
        width: '100%',
        marginBottom: '20px'
    },
    textField: {
        width: '100%',
        marginBottom: '20px'
    },
    loginBox: {

    },
    loginButton: {
        width: '100%',
        margin: '20px 0px'
    },
    icon: {
        color: theme.mylawyer.colors.textVariant,
        marginRight: theme.spacing(2),
    },
    centerText: {
        textAlign: 'center',
        width: '100%'
    },
    loginSupport: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'no-wrap',
        padding: 0,
        width: '100%'
    },
    errorArea: {
        backgroundColor: theme.mylawyer.colors.primary,
        color: theme.mylawyer.colors.textWithPrimary,
        width: '100%',
        padding: theme.spacing(1),
        marginBottom: theme.spacing(3)
    },
    errorText: {
        display: 'flex',
        alignItems: 'center',
        '& span': {
            paddingLeft: 10,
        }
    }
}));


function LoginScreen(props) {
    const classes = useStyles();
    const { dispatch, auth, countries } = props;
    const [loadingIndicator, setLoadingIndicator] = useState(true);
    const [message, setMessage] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [reminderPassword, setReminderPassword] = useState(false);
    const [reminderPasswordEmail, setReminderPasswordEmail] = useState('');
    const [newUser, setNewUser] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [displayName, setDisplayName] = useState('');
    const [country, setCountry] = useState('');
    const [userType, setUserType] = useState('');
    const [profileError, setProfileError] = useState({});
    const [loginError, setLoginError] = useState('');
    const loaded = React.useRef(false);

    const firebase = useFirebase();

    useEffect(() => {
        setTimeout(function() {
            setLoadingIndicator(false);
        }, 2000);
    },[])


    useFirebaseConnect([
        {
            path: "/config/countries",
            storeAs: "countries"
        }
    ])

    const _handleFormChangeEmail = (event) => {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        setUsername(event.target.value)
        let _profileError = profileError;
        delete _profileError.username

        if (!re.test(username)) {
            _profileError.username = 'The email you have entered does not appear to be correct.';
        }
        setProfileError(_profileError);
    }
    const _handleFormChangePassword = (event) => {
        let _profileError = profileError;
        delete _profileError.password;
        setPassword(event.target.value)
        setProfileError(_profileError)
    }

    const _loginWithEmail = (event) => {
        firebase
            .auth()
            .signInWithEmailAndPassword(
                username,
                password)
            .catch((error) =>{
                console.log(error);
                setLoginError(error.message)
            });

        event.preventDefault();
    }

    const _loginWithGoogle = () => {
        let googleAuthProvider = new firebase.auth.GoogleAuthProvider();
        firebase.auth().signInWithRedirect(googleAuthProvider);
    }

    const _loginWithFacebook = () => {
        let fbAuthProvider = new firebase.auth.FacebookAuthProvider();
        firebase.auth().signInWithRedirect(fbAuthProvider);
    }

    const _forgotPasswordStart = () => {
      setReminderPassword(true);
    }
    const _forgotPasswordClose = () => {
        setReminderPassword(false);
    }
    const _handleForgotPasswordFormChange = (event) => {
        setReminderPasswordEmail(event.target.value)
    }
    const _forgotPasswordSend = () => {
        firebase.auth().sendPasswordResetEmail(reminderPasswordEmail).then(function() {
            setReminderPassword(false);
            setMessage('You should receive a password reset email shortly.')
        }).catch(error => {
            setReminderPassword(false);
            setMessage('Password reset email could not be sent.  Have you entered the right email address?')
        })
    }

    let passwordReminderDialog = (
        <Dialog onClose={_forgotPasswordClose} aria-labelledby="reminder-dialog-title" open={reminderPassword}>
            <DialogTitle id="reminder-dialog-title">Reset my password</DialogTitle>
            <DialogContent>
            <DialogContentText>
                To have a password reset link sent to your email address please complete the following
            </DialogContentText>
            <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Email Address"
                type="email"
                fullWidth
                onChange={(e) => _handleForgotPasswordFormChange(e) }
            />
            </DialogContent>
            <DialogActions>
            <Button onClick={_forgotPasswordClose} color="primary">
                Cancel
            </Button>
            <Button onClick={(e) => {_forgotPasswordSend(e)} } color="primary">
                Send
            </Button>
            </DialogActions>
        </Dialog>
    )

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
      };

      const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };

    const _handleFormChangeDisplayName = (event) => {
        let _profileError = profileError;
        delete _profileError.displayName
        setDisplayName(event.target.value)
        setProfileError(_profileError)
    }

    const _handleFormChangeCountry = (event) => {
        let _profileError = profileError;
        delete _profileError.country
        setCountry(event.target.value)
    }

    const _handleFormChangeUserType = (event) => {
        setUserType(event.target.value)
    }

    const _newUserStart = () => {
        setNewUser(true);
    }

    const _newUserClose = () => {
        setNewUser(false);
    }

    const _newUserSave = () => {
        try {
            let _profileError = profileError;
            if (username.trim() == '') {
                _profileError.username = 'The email address cannot be empty';
            }

            if (password.trim().length < 6) {
                _profileError.password = 'The password cannot be empty and must be more than six characters';
            }
            if (displayName.trim() == '') {
                _profileError.displayName = 'The display name cannot be empty';
            }
            if (country.trim() == '') {
                _profileError.country = 'Please select your country';
            }
            setProfileError(_profileError)

            if (Object.keys(profileError).length > 0) {
                return;
            }

            const userIsLawyer = userType == 'lawyer';

            firebase.auth().createUserWithEmailAndPassword(username, password)
            .then(function(user){
                var privateUserProfile = {
                    name: displayName,
                    location_primary: country,
                    location_primary_name: countries[country],
                    profile_is_lawyer: userIsLawyer,
                    pref_is_public: userIsLawyer,
                    pref_is_contact_visible: userIsLawyer
                    };
                firebase.ref("user_private/" + (user.uid ? user.uid : user.user?.uid)).update(privateUserProfile);
                //We need to trigger an email verification
                return firebase.auth().currentUser.sendEmailVerification().then(function() {
                    return user;
                }).catch(function(error) {
                    console.log(error);
                    //TODO - change to showing an error
                    throw error;
                });
            })
        } catch(e) {
            setProfileError({ ...profileError, 'general': e.message})
        }

    }

    let newUserDialog = null;
    if (newUser) {
        let displayCountries = [];
        for (var cy in countries) {
            displayCountries.push([cy, countries[cy]])
        }
        displayCountries.sort(function(a,b) {
            return ('' + a[1]).localeCompare(b[1]);
        })
        let displayCountriesMenuItems = displayCountries.map(country => {
            return (<MenuItem value={ country[0] } key={ country[0] }>{ country[1] }</MenuItem>)
        })
        let errors = [];
        Object.keys(profileError).forEach((key) => {
            let item = profileError[key];
            errors.push(<ListItem key={key}>{item}</ListItem>)
        })

        newUserDialog = (
            <Dialog onClose={_newUserClose} aria-labelledby="new-user-dialog-title" open={newUser}>
                <DialogTitle id="new-user-dialog-title">Join the myLawyer network</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    To create a user account please complete the following information.
                </DialogContentText>
                <DialogContentText>
                    {errors}
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Email Address"
                    type="email"
                    fullWidth
                    required={true}
                    value={username}
                    onChange={(e) => _handleFormChangeEmail(e) }
                />
                <FormControl className={clsx(classes.margin, classes.textField)}>
                    <InputLabel htmlFor="standard-adornment-password" required={true}>Password</InputLabel>
                    <Input
                        id="standard-adornment-password"
                        label="Password"
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        required={true}
                        onChange={(e) => _handleFormChangePassword(e) }
                        endAdornment={
                            <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                            >
                                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
                <FormControl className={clsx(classes.margin, classes.textField)}>
                    <InputLabel htmlFor="display-name" required={true}>Display name</InputLabel>
                    <Input
                        id="display-name"
                        label="Display name"
                        type= 'text'
                        required={true}
                        value={displayName}
                        onChange={(e) => _handleFormChangeDisplayName(e) }
                    />
                </FormControl>
                <FormControl component="fieldset" className={clsx(classes.margin, classes.textField)}>
                    <FormLabel component="legend" required={true}>Are you a</FormLabel>
                    <RadioGroup aria-label="user-type" name="usertype1" value={userType} required={true} onChange={_handleFormChangeUserType}>
                        <FormControlLabel value="lawyer" control={<Radio />} label={(process.env.REACT_APP_PROJECT_FLAVOUR === 'MYBARRISTER') ? "Barrister" : "Lawyer"} />
                        <FormControlLabel value="other" control={<Radio />} label={(process.env.REACT_APP_PROJECT_FLAVOUR === 'MYBARRISTER') ? "Not a barrister" : "Not a lawyer"} />
                    </RadioGroup>
                </FormControl>
                <FormControl className={classes.textField}>
                    <InputLabel id="country-select-label" required={true}>Country</InputLabel>
                    <Select
                        labelId="country-select-label"
                        id="country-select"
                        value={country}
                        required={true}
                        onChange={_handleFormChangeCountry}
                        >
                        <ListSubheader>Selected countries</ListSubheader>
                        <MenuItem value="US" key="US1">United States</MenuItem>
                        <MenuItem value="GB" key="GB1">United Kingdom</MenuItem>
                        <MenuItem value="IN" key="IN1">India</MenuItem>
                        <ListSubheader>All countries</ListSubheader>
                        {displayCountriesMenuItems}
                    </Select>
                </FormControl>
                </DialogContent>
                <DialogActions>
                <Button onClick={_newUserClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={_newUserSave} color="primary">
                    Sign up
                </Button>
                </DialogActions>
            </Dialog>
        );
    }



    let loginForm = null;
    if (!loadingIndicator && isEmpty(auth)) {
        loginForm = (
            <Container className={classes.root}>
                <div className={classes.logoArea} ><Logo open/></div>
                    <div className={classes.message}>{message}</div>
                <Card className={classes.loginBox}>
                    <CardContent>
                        {loginError && <Grid container>
                            <Grid row className={classes.errorArea}>
                                <Typography className={classes.errorText}><ErrorIcon/><span>{loginError}</span></Typography>
                            </Grid>
                        </Grid>}
                        <form noValidate autoComplete="off" onSubmit={(e) => _loginWithEmail(e)}>
                            <TextField className={classes.loginField} id="email" label="Email address" variant="outlined"
                                InputProps={{
                                    startAdornment: (<InputAdornment position="start">
                                        <AccountCircleIcon />
                                    </InputAdornment>)
                                }} onChange={(e) => _handleFormChangeEmail(e) } />
                            <TextField className={classes.loginField} id="password" label="Password" variant="outlined" type="password"
                                InputProps={{
                                    startAdornment: (<InputAdornment position="start">
                                        <LockIcon />
                                    </InputAdornment>)
                                }} onChange={(e) => _handleFormChangePassword(e) }/>
                            <Button type="submit" className={classes.loginButton} variant="contained">Login</Button>
                        </form>
                        <Grid container>
                            <Typography className={classes.centerText}>OR</Typography>
                        </Grid>
                        <Grid container>
                            <Button className={classes.loginButton} variant="contained" color="primary" disableElevation onClick={(e) => { _loginWithFacebook() }}>Login with Facebook</Button>
                        </Grid>
                        <Grid container>
                            <Button className={classes.loginButton} variant="contained" color="primary" disableElevation onClick={(e) => { _loginWithGoogle() }}>Login with Google</Button>
                        </Grid>
                        <Divider/>
                        <Grid container>
                            <ul className={classes.loginSupport}>
                                <li style={{'listStyleType': 'none', 'marginRight': '20px'}}>
                                    <Link href="#" onClick={ _forgotPasswordStart }>
                                        Can't log in?
                                    </Link>
                                </li>
                                <li><Link href="#" onClick={ _newUserStart }>
                                        Sign up for an account
                                    </Link>
                                </li>
                            </ul>
                            {(process.env.REACT_APP_PROJECT_FLAVOUR === 'MYBARRISTER') && <ul className={classes.loginSupport} style={{'marginTop': 0}}>
                                <li style={{'listStyleType': 'none', 'marginRight': '20px'}}>
                                <Link href="https://www.mybarrister.co.uk/myB-app-terms" target="_blank">
                                        Terms
                                    </Link>
                                </li>
                            </ul>}
                        </Grid>
                    </CardContent>
                </Card>
                { passwordReminderDialog }
                { newUserDialog }
            </Container>
        )
    }
    else {
        // Logged in - redirect to main page?
        loginForm = (
            <Container className={classes.root}>
                <div className={classes.logoArea} ><Logo open/></div>
                <div className={classes.message}>{message}</div>
                <LinearProgress/>
            </Container>
        )
    }
    return (
        <div>
            {loginForm}
        </div>
    )
}


const enhance = compose(
    connect(
        // Map redux state to component props
        ({
            firebase: { auth, data: {countries}}
        }) => ({
            auth,
            countries
        })
    )
)

export default enhance(LoginScreen);
