/**
 * This Theme will override and extend the default Material-UI theme
 * See https://material-ui.com/customization/default-theme/
 */

const color = {
    background: '#003049', //night-blue
    primary: '#f77f00', //orange
    primaryVariant: '#eae2b7', //burnt-cream
    secondary: '#d62828', //red
    secondaryVariant: '#fcbf49', //yellow-orange
    text: '#ffffff', //white
    textVariant: '#eae2b7', //burnt-cream
    textWithPrimary: '#ffffff', //white
    textVariantBackground: '#004b73', //dark-blue
    colorPrimaryDark: '#d66e00', //desert-orange
    textOnSecondary: '#ffffff',
    colorSecondaryDisabled: '#d9d7c8', //khaki-grey
    colorPrimaryLight: '#e7ecf3',
    navbarBackground: '#003049', //primary
    textWithNavbar: '#ffffff', //text-with-primary
    dialogBackground: '#f6f6f3',
    textOnDialog: '#003049'
};

export default {
    palette: {
        text: {
            primary: color.text,
            secondary: color.textVariant
        },
        secondary: {
            light: color.secondaryVariant,
            main: color.secondary,
        }
    },
    typography: {
        fontFamily: '\'Lato\', sans-serif'
    },
    shape: {
        borderRadius: 0
    },
    overrides: {
        MuiDialogTitle: {
            root: {
                backgroundColor: color.dialogBackground,
                color: color.textOnDialog
            }
        },
        MuiDialogContent: {
            root: {
                backgroundColor: color.dialogBackground,
                color: color.textOnDialog,
                "& input, & label, & p": {
                    color: color.textOnDialog
                }
            }
        },
        MuiDialogActions: {
            root: {
                backgroundColor: color.dialogBackground,
                color: color.textOnDialog
            }
        }
    },
    mylawyer: {
        colors: color
    }
};
