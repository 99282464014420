import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

import { userImageUrl } from '../../util/image'
import { userSelect } from '../../redux/action/myNetwork'
import { userProfileSelect } from '../../redux/action/userProfile';
import HTMLParser from '../HTMLParser'

function NetworkListItem(props) {

    const { dispatch, member, user_ids_state, userSelectedCallback } = props;


    function handleUserItemClick(user_id_click) {
        const targetUserId = user_id_click;
        //Allow other components to use this and catch the selection
        if (userSelectedCallback) {
            userSelectedCallback(targetUserId);
        } else {
            dispatch(userSelect(targetUserId));
            dispatch(userProfileSelect(targetUserId));
        }
    }

    return (
        <ListItem
            button
            alignItems="flex-start"
            onClick={() => { handleUserItemClick(member.id) }}
            selected={user_ids_state?.includes(member.id)}
        >
            <ListItemAvatar>
                <Avatar alt={member.name} src={userImageUrl(member.id)} />
            </ListItemAvatar>
            <ListItemText primary={member.name} secondary={<HTMLParser value={member?.profile_description ?? ""} />} />
        </ListItem>
    )
}

const enhance = compose(
    connect(
        // Map redux state to component props
        ({ myNetwork: { user_ids } }) => ({ user_ids_state: user_ids })
    )
)

export default enhance(NetworkListItem);
