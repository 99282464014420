/**
 * This Theme will override and extend the default Material-UI theme
 * See https://material-ui.com/customization/default-theme/
 */

const color = {
    background: '#22223b',
    primary: '#4a4e69',
    primaryVariant: '#3f4259',
    secondary: '#9a8c98',
    secondaryVariant: '#9a8c98',
    text: '#ffffff',
    textVariant: '#f2e9e4',
    textWithPrimary: '#fdfffc',
    textVariantBackground: '#e4e1e4',
    colorPrimaryDark: '#3f4259',
    textOnSecondary: '#ffffff',
    colorSecondaryDisabled: '#e4e1e4',
    colorPrimaryLight: '#6d7398',
    navbarBackground: '#4a4e69', //primary
    textWithNavbar: '#fdfffc', //text-with-primary
    dialogBackground: '#e4e1e4',
    textOnDialog: '#3f4259'
};

export default {
    palette: {
        text: {
            primary: color.text,
            secondary: color.textVariant
        },
        secondary: {
            light: color.secondaryVariant,
            main: color.secondary,
        }
    },
    typography: {
        fontFamily: '\'Lato\', sans-serif'
    },
    shape: {
        borderRadius: 0
    },
    overrides: {
        MuiDialogTitle: {
            root: {
                backgroundColor: color.dialogBackground,
                color: color.textOnDialog
            }
        },
        MuiDialogContent: {
            root: {
                backgroundColor: color.dialogBackground,
                color: color.textOnDialog,
                "& input, & label, & p": {
                    color: color.textOnDialog
                }
            }
        },
        MuiDialogActions: {
            root: {
                backgroundColor: color.dialogBackground,
                color: color.textOnDialog
            }
        }
    },
    mylawyer: {
        colors: color
    }
};
