import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'

import {
    ACTION_CHANNEL_SELECT,
    ACTION_CHANNEL_OPS_MORE,
    ACTION_MESSAGE_MORE,
} from './type'

export function channelSelect(channel_id) {
    return {
        type: ACTION_CHANNEL_SELECT,
        channel_id
    }
}

export function channelMore() {
    return {
        type: ACTION_CHANNEL_OPS_MORE,
    }
}

export function messageMore() {
    return {
        type: ACTION_MESSAGE_MORE,
    }
}

export function channelDelete(channelId) {
    return function (dispatch) {
        console.log("TODO: delete channel");
        dispatch(channelSelect(null));
        return Promise.resolve();
    }
}
