import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { useHistory } from "react-router-dom";

import strings from '../../translate/strings'
import Button from '../Button'
import {
    userProfileSelect,
    intentInvitationFulfill,
    intentInvitationInstantFulfill,
    invitationConnect
} from '../../redux/action/userProfile'
import { channelSelect } from '../../redux/action/channelOps'

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
    connectButtonWrapper: {
        'background-color': theme.mylawyer.colors.primary,
        'padding': '1em',
        display: 'inline-block'
    }
}));

function UserProfileConnectButton(props) {
    const classes = useStyles();
    const history = useHistory();

    const { dispatch, selected_user_id, intent_invitation, user_ops_contact } = props;

    function handleConnectClick() {
        dispatch(invitationConnect(selected_user_id));
        //We dont have the channel id here to redirect, but the connect button will update to chat.
    }

    function handleChatClick() {
        if (typeof user_ops_contact?.channel_id === "string") {
            dispatch(channelSelect(user_ops_contact?.channel_id));
            history.push("/discussions");
        }
        dispatch(userProfileSelect(''));
    }

    function handleInvitationClick() {
        dispatch(intentInvitationFulfill(selected_user_id));
        //TODO: inform user invitation is sent
        dispatch(userProfileSelect(''));
    }

    function handleInvitationInstantClick() {
        dispatch(intentInvitationInstantFulfill(selected_user_id));
        //TODO: inform user invitation is sent
        dispatch(userProfileSelect(''));
    }

    let handleClick = handleConnectClick;
    let value = strings.user_profile_connect_button;

    if (intent_invitation && intent_invitation.type === "invitation") {
        value = strings.user_profile_invite_button;
        handleClick = handleInvitationClick;
    }
    else if (intent_invitation && intent_invitation.type === "case_channel_invitation") {
        value = strings.user_profile_invite_instant_button;
        handleClick = handleInvitationInstantClick;
    }
    else if (user_ops_contact && user_ops_contact.channel_id) {
        value = strings.user_profile_chat_button
        handleClick = handleChatClick;
    }

    return (
        <Paper elevation={0} className={classes.connectButtonWrapper}>
            <Button
                variant="contained"
                onClick={() => handleClick()}
                value={value} />
        </Paper>
    )
}



const enhance = compose(
    connect(
        ({
            firebase: {
                ordered: { user_public }
            },
            userProfile: { selected_user_id, intent_invitation }
        }) => ({
            selected_user_profiles: user_public,
            selected_user_id,
            intent_invitation
        })
    )
)

export default enhance(UserProfileConnectButton);
